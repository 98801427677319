import serverApi from "../../utils/serverApi"

const documentsService = {

  getDocuments: (data) => serverApi({uri: 'admin/document-versioning', method: 'GET', data}),

  createDocument: (data) => serverApi({uri: 'admin/document-versioning', method: 'POST', data}),

  updateDocument: (id, data) => serverApi({uri: `admin/document-versioning/${id}`, method: 'PUT', data}),

  deleteDocument: (id) => serverApi({uri: `admin/document-versioning/${id}`, method: 'DELETE'}),

  getVersionConfigs: () => serverApi({uri: 'admin/document-versioning/configs', method: 'GET' }),

  updateVersionConfigs: (data) => serverApi({uri: 'admin/document-versioning/configs', method: 'POST', data}),
};

export default documentsService;
