export const USER_PERMISSIONS = {
  PROPERTY: {
    CREATE_PROPERTY: 'create-property',
    COPY_PROPERTY: 'create-property',
    UPDATE_PROPERTY: 'update-property',
    UPDATE_PROPERTY_STATUS_AND_TOKEN: 'update-property-status-and-token',
    APPROVE_PROPERTY_STATUS_AND_TOKEN: 'update-property-status-and-token',
    UPDATE_PROPERY_DETAIL: 'update-property-detail',
    DELETE_PROPERTY: 'delete-property',
    VIEW_PROPERTY: 'view-property',
    VIEW_PROPERTY_OWN_BY_USER: 'view-property-own-by-user',
    VIEW_PROPERTY_HOLDER: 'view-property-holder',
    VIEW_PROPERTY_OWN_BY_USER_VIRTUAL: 'view-property-own-by-user-virtual',
    VIEW_PROPERTY_MILESTONE: 'view-all-milestone',
    CREATE_OR_UPDATE_MILSTONE: 'create-or-update-milestone',
  },
  DISTRIBUTION: {
    VIEW_RENTAL_DISTRIBUTION: 'view-rental-distribution',
    CREATE_RENTAL_DISTRIBUTION: 'create-rental-distribution',
    UPDATE_RENTAL_DISTRIBUTION: 'update-rental-distribution',
    VIEW_RENTAL_DISTRIBUTION_INFO: 'view-rental-distribution-info',
    VIEW_RENTAL_DISTRIBUTION_DEDUCTION: 'view-rental-distribution-deduction',
    VIEW_RENTAL_DISTRIBUTION_DEDUCTION_TYPE: 'view-rental-distribution-deduction-type',
    CREATE_RENTAL_DISTRIBUTION_DEDUCTION_TYPE: 'create-rental-distribution-deduction-type',
    UPDATE_RENTAL_DISTRIBUTION_DEDUCTION_TYPE: 'update-rental-distribution-deduction-type',
    DELETE_RENTAL_DISTRIBUTION_DEDUCTION_TYPE: 'delete-rental-distribution-deduction-type',
    VIEW_RENTAL_DISTRIBUTION_DETAIL: 'view-rental-distribution-detail',
    CREATE_RENTAL_DISTRIBUTION_DETAIL: 'create-rental-distribution-detail',
  },
  PROPERTY_IMAGE: {
    VIEW_PROPERTY_IMAGE: 'view-property-image'
  },
  PROPERTY_FINANCIAL: {
    VIEW_ALL_PROPERTY_FINANCIALS: 'view-all-property-financials'
  },
  ADMIN_LOGS: {
    VIEW_ADMIN_LOGS: 'view-admin-logs'
  },
  USER: {
    VIEW_KTP_PASSPORT_SELFIE: 'view-user-card-info',
    UPDATE_KTP_PASSPORT_SELFIE: 'update-user-card-info',
    UPDATE_WITHDRAWAL_STATUS: 'update-withdrawal-status',
  },
  VIDEO: {
    VIEW_VIDEO: 'view-video',
    CREATE_VIDEO: 'create-video',
    UPDATE_VIDEO: 'update-video',
    DELETE_VIDEO: 'delete-video',
    VIEW_VIDEO_PLAYLIST: 'view-video-playlist',
    CREATE_VIDEO_PLAYLIST: 'create-video-playlist',
    UPDATE_VIDEO_PLAYLIST: 'update-video-playlist',
    DELETE_VIDEO_PLAYLIST: 'delete-video-playlist',
  },
  DOCUMENT_VERSIONING: {
    VIEW: 'view-document-versioning',
    CREATE: 'create-document-versioning',
    UPDATE: 'update-document-versioning',
    DELETE: 'delete-document-versioning',
  },
  CONFIG: {
    VIEW: 'view-config',
    CREATE_OR_UPDATE: 'create-or-update-config',
  },
  ATTESTATION_REPORT: {
    VIEW: 'view-attestation-report',
    CREATE: 'create-attestation-report',
    UPDATE: 'update-attestation-report',
    DELETE: 'delete-attestation-report',
  },
}
