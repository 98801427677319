<template>
    <div class="twofa">
        <p class="font-28 font-weight-bold">{{ $t('TWO_FA.TWO_FACTOR_AUTHENTICATION') }}</p>
        <div class="content">
            <div v-if="!isShowingInstruction">
                <p class="font-weight-bold font-18">{{ $t('TWO_FA.ADD_EXTRA_SECURITY_TO_YOUR_ACCOUNT') }}</p>
                <p class="font-16 mt-3">{{ $t('TWO_FA.DESCRIPTION') }}</p>
                <div class="authentication-app d-flex flex-row align-items-center col-12 col-lg-10 mt-4"
                    @click="showInstruction">
                    <div class="flex-grow-1">
                        <p class="font-weight-bold">{{ $t('TWO_FA.AUTHENTICATION_APP') }}</p>
                        <p class="mt-3">{{ $t('TWO_FA.AUTHENTICATION_APP_DESCRIPTION') }}</p>
                    </div>
                    <b-icon v-if="enabled" v-on:click.stop="toggleAuthApp(false)" icon="toggle-on" class="mx-4"
                        style="color: var(--primary-color);" scale="2"></b-icon>
                    <b-icon v-if="!enabled" v-on:click.stop="toggleAuthApp(true)" icon="toggle-on"
                        class="mx-4 icon-disabled" style="color: gray;" scale="2"></b-icon>
                    <b-icon icon="chevron-right" scale="1.3" style="color: var(--primary-color);"></b-icon>
                </div>
                <div v-if="enabled" class="authentication-app d-flex flex-row align-items-center col-12 col-lg-10 mt-3"
                    @click="showRecoveryCodes">
                    <div class="flex-grow-1">
                        <p class="font-weight-bold">{{ $t('TWO_FA.BACKUP_CODES') }}</p>
                        <p class="mt-3">{{ $t('TWO_FA.BACKUP_CODES_DESC') }}</p>
                    </div>
                    <!-- <b-icon v-on:click.stop="toggleRecoveryCode(false)" v-if="enabledRecoveryCodes" icon="toggle-on"
                        class="mx-4" style="color: var(--primary-color);" scale="2"></b-icon>
                    <b-icon v-if="!enabledRecoveryCodes" v-on:click.stop="toggleRecoveryCode(true)" icon="toggle-on"
                        class="mx-4 icon-disabled" style="color: gray;" scale="2"></b-icon> -->
                    <b-icon icon="chevron-right" scale="1.3" style="color: var(--primary-color);"></b-icon>
                </div>
            </div>
            <div v-if="isShowingInstruction" class="instruction">
                <div class="d-flex justify-content-end">
                    <div class="btn-close" @click="isShowingInstruction = false">
                        <b-icon icon="x" style="color: gray;" scale="1.6"></b-icon>
                    </div>
                </div>
                <p class="font-weight-bold font-18">{{ $t('TWO_FA.INSTRUCTION_FOR_SETUP') }}</p>
                <p class="font-weight-bold mt-4">{{ $t('TWO_FA.INSTRUCTION_STEP_1') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.INSTRUCTION_STEP_1_DESC') }} <span class="btn-copy"
                        @click="downloadAuthy">{{ $t('TWO_FA.CLICK_HERE') }}</span></p>
                <p class="font-weight-bold mt-4">{{ $t('TWO_FA.INSTRUCTION_STEP_2') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.INSTRUCTION_STEP_2_DESC') }}</p>
                <div
                    class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-between col-12 col-lg-10 col-xl-8 mt-3">
                    <div>
                        <p class="font-weight-bold font-18 text-break">{{ authCode.key }}</p>
                        <p class="btn-copy" @click="copyKey">{{ $t('TWO_FA.COPY_KEY') }}</p>
                    </div>
                    <div v-html="authCode.qr_code"></div>
                </div>
                <p class="font-weight-bold mt-4">{{ $t('TWO_FA.INSTRUCTION_STEP_3') }}</p>
                <p class="mt-2">{{ $t('TWO_FA.INSTRUCTION_STEP_3_DESC') }}</p>
                <div class="text-center">
                    <b-button class="btn-main col-8 col-lg-6 col-xl-5 mt-5" variant="none" @click="nextStep()">
                        {{ $t("common.NEXT") }}
                    </b-button>
                </div>
            </div>
        </div>
        <PopupInput2FAOTP ref="popupInput2FAOTP" @on-success="onSuccess" />
        <PopupRevoveryCodes ref="popupRevoveryCodes" @on-success="onSuccessRecoveryCodes" />
        <PopupTurnOffAuthenticaitonApp ref="popupTurnOffAuthenticationApp" @on-success="check2FAStatus" />
    </div>
</template>

<script>

import twofaService from '../../services/twofa.service';
import { notify } from "@/helpers/common";
import PopupInput2FAOTP from '../../components/PopupInput2FAOTP.vue';
import PopupRevoveryCodes from '../../components/PopupRevoveryCodes.vue';
import PopupTurnOffAuthenticaitonApp from '../../components/PopupTurnOffAuthenticaitonApp.vue';

export default {
    components: {
        PopupInput2FAOTP,
        PopupRevoveryCodes,
        PopupTurnOffAuthenticaitonApp,
    },

    data() {
        return {
            enabled: false,
            isShowingInstruction: false,
            authCode: {},
            recoveryCodes: [],
        }
    },

    mounted() {
        this.check2FAStatus()
        this.getRecoveryCodes()
    },

    methods: {
        async check2FAStatus() {
            const res = await twofaService.check2FAStatus()
            if (res && res.data) {
                this.enabled = res.data === 'enabled'
            }
        },

        async getRecoveryCodes() {
            const res = await twofaService.getRecoveryCodes()
            if (res) {
                this.recoveryCodes = res.data
            }
        },

        async getAuthCode() {
            const res = await twofaService.getAuthCode()
            return res && res.data
        },

        async showInstruction() {
            const data = await this.getAuthCode()
            if (data) {
                this.authCode = data
                this.isShowingInstruction = true
            }
        },

        copyKey() {
            if (this.authCode.key) {
                navigator.clipboard.writeText(this.authCode.key);
                notify({ text: this.$t('common.COPIED') });
            }
        },

        nextStep() {
            this.$refs.popupInput2FAOTP.openPopup()
        },

        onSuccess() {
            this.isShowingInstruction = false
            this.check2FAStatus()
            this.getRecoveryCodes()
        },

        onSuccessRecoveryCodes() {
            this.getRecoveryCodes()
        },

        showRecoveryCodes() {
            this.$refs.popupRevoveryCodes.openPopup()
        },

        toggleAuthApp(isOn) {
            if (isOn) {
                this.showInstruction()
            } else {
                this.$refs.popupTurnOffAuthenticationApp.openPopup()
            }
        },

        toggleRecoveryCode(isOn) {
            this.$refs.popupRevoveryCodes.openPopup(!isOn)
        },

        downloadAuthy() {
            let operatingSystem = '';
            const browserName = this.detectBrowser.meta.name.toLowerCase()
            let userAgent = this.detectBrowser.meta.ua.toLocaleLowerCase()
            if (userAgent.includes("win")) operatingSystem = "Windows"
            else if (userAgent.includes("mac")) operatingSystem = "MacOS"
            else if (userAgent.includes("ios")) operatingSystem = "iOS"
            else if (userAgent.includes("android")) operatingSystem = "Android"
            else if (userAgent.includes("linux")) operatingSystem = "Linux"
            else operatingSystem = "Unknown os"

            let authyStoreUrl = 'https://play.google.com/store/apps/details?id=com.authy.authy'
            if (operatingSystem === 'iOS' || operatingSystem === 'MacOS' || browserName === 'safari') {
                authyStoreUrl = 'https://apps.apple.com/us/app/twilio-authy/id494168017'
            }
            window.open(authyStoreUrl, "_blank")
        },
    },

    computed: {
        enabledRecoveryCodes() {
            return this.recoveryCodes.length
        },
    },
}
</script>

<style lang="scss" scoped>
.twofa {
    width: 100%;
    margin-top: 20px;

    .content {
        color: black;
        border-radius: 16px;
        padding: 20px;
        margin-top: 15px;
        min-height: 80vh;

        .authentication-app {
            background-color: white;
            box-shadow: 0 8px 35px rgba(7, 55, 99, 0.16);
            border-radius: 6px;
            padding: 16px 24px 16px 24px;
            border: solid 1px rgb(243, 243, 243);
            cursor: pointer;
        }

        .img-qr {
            width: 200px;
            height: 200px;
        }

        .btn-copy {
            color: blue;
            cursor: pointer;
            width: fit-content;
            border-radius: 4px;
            margin-top: 6px;
        }

        .icon-disabled {
            transform: rotate(180deg);
        }

        .instruction {
            background-color: white;
            box-shadow: 0 8px 35px rgba(7, 55, 99, 0.16);
            border-radius: 16px;
            padding: 20px;

            .btn-close {
                background-color: rgb(221, 221, 221);
                border-radius: 50%;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}
</style>