<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button v-if="canAddDocument" size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add New
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="reports.data" :columns="tableColumns" :imageColumns="imageColumns"
                        :htmlColumns="htmlColumns" :show-edit-action="canUpdateDocument"
                        :show-delete-action="canDeleteDocument" @onModalEdit="onModalEdit"
                        @onModalDelete="onModalDelete">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="3%"></th>
                                <th width="20%">
                                    <b-input v-model="filters.uuid"></b-input>
                                </th>
                                <th width="30%">
                                    <b-input v-model="filters.title"></b-input>
                                </th>
                                <th width="10%">
                                    <b-form-select v-model="filters.quarter">
                                        <template #first>
                                            <b-form-select-option :value="null">Select quarter</b-form-select-option>
                                        </template>
                                        <template v-for="quarter in quarters">
                                            <b-form-select-option :value="quarter">
                                                Q{{ quarter }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="10%">
                                    <b-form-select v-model="filters.year">
                                        <template #first>
                                            <b-form-select-option :value="null">Select year</b-form-select-option>
                                        </template>
                                        <template v-for="year in years">
                                            <b-form-select-option :value="year">
                                                {{ year }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="17%"></th>
                                <th v-if="showActions" width="10%"></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="reports.total" align="right" v-model="reports.current_page"
                        :total-rows="reports.total" :per-page="reports.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-add-attestation-report :show="showModalAdd" @on-close="hideModalAdd" :report="report" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteAttestationReport" type="button" class="close font-weight-bold"
                    aria-label="Close" @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this document?
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteConfig">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import moment from 'moment'
import { PaperTable } from "../../components";
import ModalBroadcastDetail from "./modals/ModalBroadcastDetail.vue"
import ModalAddAttestationReport from './modals/ModalAddAttestationReport.vue';
import attestationService from "../../services/admin/attestation.service";
import { userHasPermission } from "../../helpers/common";
import { USER_PERMISSIONS } from "../../constants/permissions";

const tableColumns = ["Id", "Uuid", "Title", "Quarter", "Year", "Upload Date"]

export default {
    components: {
        PaperTable,
        ModalBroadcastDetail,
        ModalAddAttestationReport,
    },
    data() {
        return {
            reports: {},
            tableColumns: [...tableColumns],
            htmlColumns: [2],
            report: null,
            showModalAdd: false,
            modalDelete: {
                show: false,
            },
            filters: {
                uuid: null,
                title: null,
                quarter: null,
                year: null,
            },
            quarters: [1, 2, 3, 4],
            years: this.generateYears(),
        };
    },
    async mounted() {
        await this.getReports(1);
    },
    watch: {
        'filters.uuid'() {
            this.searchTimeOut(1);
        },
        'filters.title'() {
            this.searchTimeOut(1);
        },
        'filters.quarter'() {
            this.searchTimeOut(1);
        },
        'filters.year'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getReports(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await attestationService.getList(filters);
            if (result && result.data) {
                this.reports = result;
                this.reports.data = this.reports.data.map(e => {
                    e.upload_date = moment(e.created_at).format('DD/MM/YYYY HH:mm:ss')
                    e.quarter = `Q${e.quarter}`
                    e.title = `
                    <div>
                        <div class="d-flex flex-row align-items-center">
                            <div class="attestation-text-lang mr-2">EN</div>
                            <p>${e.title_en}</p>
                        </div>
                        <div class="d-flex flex-row align-items-center mt-2">
                            <div class="attestation-text-lang mr-2">ID</div>
                            <p>${e.title_id}</p>
                        </div>
                    </div>
                    `
                    return e
                })
            }
        },

        async onChangePage(page) {
            await this.getReports(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getReports(page);
            }, 400);
        },

        onModalEdit(data) {
            if (data) {
                this.report = this.reports.data.find((e) => e.id === data);
                this.showModalAdd = true;
            }
        },

        onModalDelete({ id }) {
            this.modalDelete = {
                show: true,
                id,
            }
        },

        async onDeleteConfig() {
            const res = await attestationService.deleteReport(this.modalDelete.id)
            if (res) {
                this.modalDelete = {
                    show: false,
                }
                this.getReports(1)
            }
        },

        showAddModal() {
            this.showModalAdd = true;
        },

        hideModalAdd(success) {
            this.showModalAdd = false;
            this.report = null;
            if (success) {
                this.getReports(1)
            }
        },

        generateYears() {
            const min = 2022;
            const max = parseInt(moment().format('YYYY'))
            return Array.from({ length: max - min + 1 }, (_, i) => min + i)
        },
    },

    computed: {
        canAddDocument() {
            return userHasPermission(USER_PERMISSIONS.ATTESTATION_REPORT.CREATE)
        },

        canUpdateDocument() {
            return userHasPermission(USER_PERMISSIONS.ATTESTATION_REPORT.UPDATE)
        },

        canDeleteDocument() {
            return userHasPermission(USER_PERMISSIONS.ATTESTATION_REPORT.DELETE)
        },

        showActions() {
            return this.canUpdateDocument && this.canDeleteDocument
        },
    },
};
</script>
<style>
.attestation-text-lang {
    width: 50px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}
</style>