<template>
  <div v-if="isEnabledShowModal" id="id-notification-sandbox-modal" class="cls-custom-popup-container cls-sandbox-modal">
    <div class="popup-dialog popup-dialog-centered" @click.self="closePopup">
      <div class="popup-content">
        <div class="popup-body text-center">
          <div class="header text-left">
            <img class="logo" autofocus width="131" :src="require(`@/assets/img/logo_with_text.png`)" alt="GORO Sandbox"/>
          </div>
          <div class="body-content">
            <div class="heading-content">
              <img class="notification-icon" width="116" :src="require(`@/assets/img/sandbox/notification.png`)" alt="GORO Sandbox"/>
              <h2 class="heading font-24 font-bold mt-2 mb-3">
                {{ $t("SANDBOX.TITLE") }}
              </h2>
            </div>
            <div class="content">
              <p class="font-16 font-normal mb-3" v-html="bodyContent_1">
              </p>
              <p class="font-16 font-normal mb-3" v-html="bodyContent_2">
              </p>
            </div>
            <div class="footer-content mt-5 d-flex align-items-center justify-content-between">
              <div class="cls-check-not-show">
                <b-form-checkbox
                  id="checkbox-not-show-30-day"
                  v-model="dontShowAgain"
                  size="lg"
                  name="dont-show-again"
                >
                  {{ $t("SANDBOX.DO_NOT_SHOW_IN_DAYS") }}
                </b-form-checkbox>
              </div>
              <b-button @click="closePopup" class="btn btn-none btn-main font-18 font-bolder btn-close" style="padding: 6px 25px 7px 25px;">
                {{ $t("SANDBOX.CLOSE") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../i18n"
import { numberWithCommas } from "../../helpers/common"

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-close"],
  data() {
    return {
      isEnabledShowModal: false,
      dontShowAgain: false,
    }
  },
  methods: {
    onClose() {
      this.isEnabledShowModal = false
    },
    closePopup() {
      if (this.dontShowAgain) {
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 30);
        localStorage.setItem("sanboxModalExpiry", expiryDate);
      }
      this.onClose();
      this.$emit('on-close')
    },
  },

  computed: {
    numberOfUsers() {
      let number = 100000
      const locale = i18n.global?.locale?.value
      const separator = locale === 'id' ? '.' : ','
      if (this.$store.state.configs && this.$store.state.configs.number_of_users) {
        number = this.$store.state.configs.number_of_users
      }

      return numberWithCommas(number, separator)
    },
    bodyContent_1() {
      return this.$t("SANDBOX.BODY_CONTENT_1")
    },
    bodyContent_2() {
      return this.$t("SANDBOX.BODY_CONTENT_2", { number: this.numberOfUsers })
    },
  },
  watch: {
    show(value) {
      this.isEnabledShowModal = value
    },
  },
}
</script>

<style lang="scss">
.cls-sandbox-modal{
  .popup-content{
    border-radius: 25px;
    max-width: 766px;
    padding: 25px;
    padding-top: 15px;
    @media(max-width: 992px) {
      padding: 15px;
      padding-top: 10px;
    }
    @media(max-width: 768px) {
      max-width: calc(100% - 1rem);
    }
    &::before{
      position: absolute;
      content: "";
      bottom: 0;
      width: 30%;
      height: 50%;
      background-position: left bottom;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      left: 0;
      background-image: url('~@/assets/img/sandbox/bg_left.png');
      @media(max-width: 992px) {
        height: 124px;
        width: 40%;
        background-image: url('~@/assets/img/sandbox/bg_mobile_left.png');
      }
    }
    &::after{
      position: absolute;
      content: "";
      bottom: 0;
      width: 30%;
      height: 50%;
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      right: 0;
      background-image: url('~@/assets/img/sandbox/bg_right.png');
      @media(max-width: 992px) {
        height: 124px;
        width: 40%;
        background-image: url('~@/assets/img/sandbox/bg_mobile_right.png');
      }
    }
    .popup-body{
      position: relative;
      z-index: 1;
      .header{
        .logo{
          @media(max-width: 992px) {
            width: 93px;
          }
        }
      }
      .body-content{
        padding: 40px;
        padding-top: 0;
        @media(max-width: 992px) {
          padding: 10px;
          padding-top: 0;
        }
        .heading-content{
          h2{
            line-height: 28.8px;
            text-align: center;
            color: #333333;
            @media(max-width: 992px) {
              // font-size: 20px !important;
              line-height: 19.2px;
            }
          }
          .notification-icon{
            @media(max-width: 992px) {
              width: 65px;
            }
          }
        }
        .content,
        .content *{
          line-height: 22px;
          text-align: center;
          color: #333333;
          font-weight: 500;
          @media(max-width: 992px) {
            font-size: 16px !important;
            line-height: 19.2px;
          }
          strong{
            font-weight: 700;
          }
        }
        .footer-content{
          @media(max-width: 992px) {
            flex-direction: column;
          }
          .cls-check-not-show{
            .custom-control{
              @media(max-width: 992px) {
                margin-bottom: 15px;
              }
            }
            .custom-control-label{
              cursor: pointer;
              font-family: "Figtree", Helvetica, sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 21.6px;
              letter-spacing: -0.03em;
              padding: 3px 0px;
              color: #3F3F3F;
              @media(max-width: 992px) {
                font-size: 16px !important;
              }
            }
          }
          .btn-close{
            min-width: 164px;
          }
        }
      }
      
    }
  }
}
</style>