<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" :title="title" @show="updateModal"
        @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group class="mt-3" label="Title EN" label-for="value-title-en" :state="validateState('title_en')">
                <b-form-input id="value-title-en" v-model="form.title_en" :state="validateState('title_en')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group class="mt-3" label="Title ID" label-for="value-title-id" :state="validateState('title_id')">
                <b-form-input id="value-title-id" v-model="form.title_id" :state="validateState('title_id')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group class="mt-3" label="Quater" label-for="value-quarter" :state="validateState('quarter')">
                <b-form-select v-model="form.quarter" :state="validateState('quarter')" required>
                    <template #first>
                        <b-form-select-option :value="null">Select quarter</b-form-select-option>
                    </template>
                    <template v-for="quarter in quarters">
                        <b-form-select-option :value="quarter">
                            Q{{ quarter }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>

            <b-form-group class="mt-3" label="Year" label-for="value-year" :state="validateState('year')">
                <b-form-select v-model="form.year" :state="validateState('year')" required>
                    <template #first>
                        <b-form-select-option :value="null">Select year</b-form-select-option>
                    </template>
                    <template v-for="year in years">
                        <b-form-select-option :value="year">
                            {{ year }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>

            <p>Document</p>
            <VueFileAgent :class="{ 'vue-file-agent-error': fileError }" ref="vueFileAgent" v-model="images"
                v-model:rawModelValue="images" :theme="'grid'" :multiple="false" :meta="false" :accept="'.pdf,.docx'"
                :maxSize="'5MB'" :maxFiles="1" :helpText="'Choose document file (PDF or DOCX)'" :errorText="{
                    type: 'Invalid file type. Only PDF or DOCX are allowed',
                    size: 'Files should not exceed 5MB in size',
                }" @beforedelete="onBeforeDeleteImage($event)" @select="filesSelectedImage($event)" required>
            </VueFileAgent>
        </form>
    </b-modal>
</template>

<script>

import moment from 'moment'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getBase64 } from "../../../helpers/common"
import attestationService from '../../../services/admin/attestation.service'

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        report: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                title_en: {
                    required,
                },
                title_id: {
                    required,
                },
                quarter: {
                    required,
                },
                year: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {},
            images: [],
            showModal: false,
            quarters: [1, 2, 3, 4],
            years: this.generateYears(),
            fileError: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {

        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },
        updateModal() {
            this.resetModal();
            this.v$.$reset()
            if (this.report) {
                if (this.report.document_link) {
                    const name = this.report.document_link.split("/").pop()
                    const images = [
                        {
                            id: this.report.id,
                            name: name,
                            tempName: name,
                            url: this.report.document_link,
                            path: this.report.document_link,
                            size: 0,
                            type: 'application/pdf',
                        }
                    ]
                    this.images = images
                }
                this.form.title_en = this.report.title_en
                this.form.title_id = this.report.title_id
                this.form.quarter = parseInt(this.report.quarter.replace('Q', ''))
                this.form.year = this.report.year
            }
        },

        resetModal() {
            this.form = {}
            this.images = []
            this.fileError = false
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            if (!this.images.length) {
                this.fileError = true
            }
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            if (this.images.length) {
                let base64Image = null;
                if (this.images.length && this.images[0].file) {
                    base64Image = await getBase64(this.images[0].file)
                }
                if (this.report) {
                    const res = await attestationService.updateReport(this.report.id, {
                        title_en: this.form.title_en,
                        title_id: this.form.title_id,
                        quarter: this.form.quarter,
                        year: this.form.year,
                        document_base_64: base64Image,
                    })
                    if (res) {
                        this.$emit('on-close', true)
                    }
                } else {
                    const res = await attestationService.createReport({
                        title_en: this.form.title_en,
                        title_id: this.form.title_id,
                        quarter: this.form.quarter,
                        year: this.form.year,
                        document_base_64: base64Image,
                    })
                    if (res) {
                        this.$emit('on-close', true)
                    }
                }
            }
        },

        onBeforeDeleteImage(fileRecord) {
            const index = this.images.indexOf(fileRecord)
            if (index !== -1) {
                this.images.splice(index, 1)
            }
        },

        async filesSelectedImage() {
            this.fileError = false
        },
        generateYears() {
            const min = 2022;
            const max = parseInt(moment().format('YYYY'))
            return Array.from({ length: max - min + 1 }, (_, i) => min + i)
        },
    },

    computed: {
        title() {
            return this.report != null ? 'Update Document' : 'Add New Document';
        },
    },
}
</script>

<style lang="scss">
.vue-file-agent-error {
    border: red solid 1px !important;
    border-radius: 5px;
}
</style>