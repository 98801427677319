<template>
    <b-modal v-model="showModal" id="full-screen-modal" ref="modal" size="huge" header-bg-variant="dark"
        header-text-variant="light" header-class="modal-header" footer-class="modal-footer" body-class="modal-body-full"
        :title="title" @show="updateModal" @hidden="$emit('on-close')" @ok="handleOk">

        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row align-h="center">
                <b-col cols="10">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group label="Title EN" label-for="title-en-input"
                                :state="validateState('title_en')">
                                <b-form-input id="title-en-input" v-model="form.title_en"
                                    :state="validateState('title_en')" required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="Title ID" label-for="title-id-input"
                                :state="validateState('title_id')">
                                <b-form-input id="title-id-input" v-model="form.title_id"
                                    :state="validateState('title_id')" required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group label="Tick Content EN" label-for="tick-en-input"
                                :state="validateState('tick_content_en')">
                                <b-form-textarea id="tick-en-input" v-model="form.tick_content_en"
                                    :state="validateState('tick_content_en')" required rows="3" max-rows="5">
                                </b-form-textarea>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="Tick Content ID" label-for="tick-id-input"
                                :state="validateState('tick_content_id')">
                                <b-form-textarea id="tick-id-input" v-model="form.tick_content_id"
                                    :state="validateState('tick_content_id')" required rows="3" max-rows="5">
                                </b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group label="Version" label-for="version-input" :state="validateState('version')">
                                <b-form-input id="version-input" v-model="form.version" type="number" :disabled="isEdit"
                                    :state="validateState('version')" required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group label="Doc Type" label-for="doc-type-input"
                                :state="validateState('doc_type')">
                                <b-form-select id="doc-type-input" v-model="form.doc_type" text-field="name"
                                    :state="validateState('doc_type')" required>
                                    <b-form-select-option :value="null" disabled>Select Doc Type</b-form-select-option>
                                    <b-form-select-option value="TERMS_AND_CONDITION">Terms &
                                        Condition</b-form-select-option>
                                    <b-form-select-option value="PRIVACY_POLICY">Privacy Policy</b-form-select-option>
                                    <b-form-select-option value="USER_CONSENT">User Consent</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6">
                            <label>Content EN</label>
                            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-content-en"
                                :api-key="tinyCloudKey" :init="editor" :initial-value="form.content_en"
                                model-events="change keydown blur focus paste" v-model="form.content_en" />
                            <p v-if="showError && !form.content_en" class="error-text">Content EN is required</p>
                        </b-col>
                        <b-col cols="6">
                            <label>Content ID</label>
                            <editor :key="editorKey" :cloud-channel="tinyMCECloudChannel" id="editor-content-id"
                                :api-key="tinyCloudKey" :init="editor" :initial-value="form.content_id"
                                model-events="change keydown blur focus paste" v-model="form.content_id" />
                            <p v-if="showError && !form.content_id" class="error-text">Content ID is required</p>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </form>
    </b-modal>
</template>

<script>

import Editor from "@anhoang/tinymce-vue"
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import documentService from '../../../services/admin/document.service';
import KeyValueInput from '../../../components/KeyValueInput.vue';

export default {
    components: {
        KeyValueInput,
        Editor,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        document: {
            type: Object,
            default: null,
        },
    },
    validations() {
        return {
            form: {
                title_en: {
                    required,
                },
                title_id: {
                    required,
                },
                tick_content_en: {
                    required,
                },
                tick_content_id: {
                    required,
                },
                version: {
                    required,
                },
                doc_type: {
                    required,
                }
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {},
            showModal: false,
            editorKey: 0,
            tinyMCECloudChannel: '4',
            tinyCloudKey: process.env.VUE_APP_TINYMCE_API_KEY,
            showError: false,
            editor: {
                height: 500,
                menubar: true,
                plugins: [
                    "advlist autolink lists link image charmap print preview anchor textcolor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount"
                ],
                toolbar:
                    "undo redo | formatselect | bold italic backcolor forecolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help",
            },
        }
    },

    watch: {
        show(value) {
            this.showModal = value
            if (value) {
                setTimeout(() => {
                    this.editorKey += 1
                }, 1000)
            }
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            if (this.document != null) {
                this.form = {
                    id: this.document.id,
                    title_en: this.document.title_en,
                    title_id: this.document.title_id,
                    content_en: this.document.content_en,
                    content_id: this.document.content_id,
                    doc_type: this.document.doc_type,
                    version: this.document.version,
                    tick_content_en: this.document.tick_content_en,
                    tick_content_id: this.document.tick_content_id,
                };
            } else {
                this.resetModal();
            }
        },

        resetModal() {
            this.form = {}
            this.showError = false
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!this.form.content_en || !this.form.content_id || !isFormCorrect) {
                this.showError = true
                return;
            }
            if (this.document && this.document.id) {
                await documentService.updateDocument(this.document.id, this.form);
            } else {
                await documentService.createDocument(this.form);
            }
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        },
    },

    computed: {
        title() {
            return this.document != null ? 'Update Document' : 'Add new Document';
        },

        isEdit() {
            return this.document != null
        },
    },
}
</script>

<style lang="scss" scoped>
.error-text {
    font-size: 14px;
    color: red;
}
</style>