import MyVue from "@vue/compat";
import Notifications from "@kyvg/vue3-notification";
import Loading from "vue-loading-overlay";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import SideBar from "@/components/SidebarPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import VueNumber from "vue-number-animation";
import VueDatePicker from '@anhoang/vue-datepicker-compat';
import '@vuepic/vue-datepicker/dist/main.css';
import VueSelect from "vue-select";
import "es6-promise/auto";
import GoogleAuth from "../helpers/googleAuth";
import detectBrowser from "vue-detect-browser";
import VueConfetti from "vue-confetti";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueTelInput from '@anhoang/vue-tel-input-compat';

//css assets
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/css/themify-icons.css";
import "@/assets/css/style.css";
import "@/assets/css/mobile.style.css";
import "@/assets/sass/style.scss";
import "@/assets/sass/paper-dashboard.scss";
import "vue-loading-overlay/dist/css/index.css";
import "vue-select/dist/vue-select.css";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import en from "@vee-validate/i18n/dist/locale/en.json";
import id from "@vee-validate/i18n/dist/locale/id.json";
import 'vue-tel-input/vue-tel-input.css';
import "@/assets/sass/orders-page.scss";
import "@/assets/sass/_custom-modal.scss";
import "@/assets/sass/_custom-scroll.scss";

const globalOptions = {
  mode: 'auto',
};

export default {
  install(Vue) {
    Vue.component("loading", Loading);
    Vue.component("v-select", VueSelect);
    // TODO: use this instead of VueDatePickerCompat when it's ready
    // Vue.component('VueDatePicker', VueDatePicker);
    Vue.component('VueDatePicker', VueDatePicker);
    Vue.use(VueNumber);
    MyVue.use(BootstrapVue);
    MyVue.use(IconsPlugin);
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(detectBrowser);

    Vue.use(GoogleAuth, {
      client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
      scope: "profile email",
      prompt: "select_account"
    })
    Vue.use(VueConfetti);
    Vue.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
      },
    });
    Vue.use(VueTelInput, globalOptions);
    configure({
      generateMessage: localize({
        en,
        id,
      }),
    });
  },
};
