export default {
  setGeoLocation (state, payload) {
    state.geoLocation = payload
  },
  setStayAtCurrentLocation (state, stayAtCurrentLocation) {
    state.stayAtCurrentLocation = stayAtCurrentLocation
  },
  setSwitchToNewLocation (state, switchToNewLocation) {
    state.switchToNewLocation = switchToNewLocation
  },
  setUserProfile(state, payload) {
    state.userProfile = payload;
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
  setConfigs(state, payload) {
    state.configs = payload;
  },
  setUnreadCount(state, payload) {
    state.unread = payload;
  },
  setAvailableBanks(state, payload) {
    state.availableBanks = payload;
  },
  setReferralCode(state, payload) {
    state.referralCode = payload;
  },
  setAssetsCount(state, payload) {
    state.assetsCount = payload
  },
  setPendingTasksCount(state, payload) {
    state.pendingTasksCount = payload
  },
  setInitialBalance(state, payload) {
    state.initialBalance = payload
  },
  setExchangeRates(state, payload) {
    state.exchangeRates = payload
  },
  setSocialLoginData(state, payload) {
    state.socialLoginData = payload
  },
  setAdminPermissions(state, payload) {
    state.adminPermissions = payload
  },
  setRequired2FA(state, payload) {
    state.required2FA = payload
  },
  setPartnerPermissions(state, payload) {
    state.partnerPermissions = payload
  },
  setGoLearnPlaylists(state, payload) {
    state.goLearnPlaylists = payload
  },
  setDocumentVersioning(state, payload) {
    state.documentVersioning = payload
  },
  setInstalledPin(state, payload) {
    state.installedPin = payload
  },
};
