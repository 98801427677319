<template>
  <div v-bind:class="{ 'nav-open': $sidebar.showSidebar }" class="cls-goro-sidebar-admin">
    <div class="wrapper">
      <side-bar>
        <template v-slot:links>
          <sidebar-link v-if="canViewLink('view-dashboard')" :to="{name: 'adminDashboard'}" name="Dashboard" icon="ti-panel"/>
          <sidebar-link v-if="canViewLink('view-admin-logs')" :to="{name: 'adminLogsDashboard'}" name="Admin Logs" icon="ti-view-list-alt"/>
          <sidebar-link v-if="canViewLink('view-property-dashboard')" :to="{name: 'tokenTransactionDashboard'}" name="Token Transaction Dashboard" icon="ti-panel"/>
          <sidebar-link v-if="canViewLink('view-user')" :to="{name: 'adminUsers'}" name="Users" icon="ti-user"/>
          <sidebar-link v-if="canViewLink('view-property')" :to="{name: 'adminProperties'}" name="Properties" icon="ti-home"/>
          <sidebar-link v-if="canViewLink('view-contract-template')" :to="{name: 'adminContractTemplates'}" name="Contract Templates" icon="ti-briefcase"/>
          <sidebar-link v-if="canViewLink('view-payment')" :to="{name: 'adminPayments'}" name="Payments" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-order')" :to="{name: 'adminOrders'}" name="Orders" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-sell-token-request')" :to="{name: 'adminSellTokenRequests'}" name="Sell Token Requests" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-withdrawal')" :to="{name: 'adminWithdrawals'}" name="Withdrawals" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-rental-distribution')" :to="{name: 'adminRentalDistributions'}" name="Rental Distributions" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-rental-distribution-detail')" :to="{name: 'adminRentalDistributionDetails'}" name="Distribution Details" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-virtual-rental-distribution')" :to="{name: 'adminVirtualRentalDistributionDetails'}" name="VDistribution Details" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-rental-distribution-deduction')" :to="{name: 'adminRentalDeductionTypes'}" name="Deduction Types" icon="ti-money"/>
          <sidebar-link v-if="canViewLink('view-waitlist')" :to="{name: 'adminWaitlists'}" name="Waitlists" icon="ti-id-badge"/>
          <sidebar-link v-if="canViewLink('view-config')" :to="{name: 'adminConfigs'}" name="Configs" icon="ti-settings"/>
          <sidebar-link v-if="canViewLink('view-role')" :to="{name: 'adminRoles'}" name="Roles" icon="ti-shield"/>
          <sidebar-link v-if="canViewLink('view-admin')" :to="{name: 'adminAdminUsers'}" name="Admins" icon="ti-id-badge"/>
          <sidebar-link v-if="canViewLink('view-partner')" :to="{name: 'adminPartner'}" name="Partners" icon="ti-id-badge"/>
          <sidebar-link v-if="canViewLink('view-referral')" :to="{name: 'adminReferrals'}" name="Referrals" icon="ti-medall-alt"/>
          <sidebar-link v-if="canViewLink('view-spc')" :to="{name: 'adminSpcs'}" name="Spcs" icon="ti-home"/>
          <sidebar-link v-if="canViewLink('view-location')" :to="{name: 'adminLocations'}" name="Locations" icon="ti-map-alt"/>
          <sidebar-link v-if="canViewLink('view-category')" :to="{name: 'adminCategories'}" name="Categories" icon="ti-folder"/>
          <sidebar-link v-if="canViewLink('view-email-template')" :to="{name: 'emailTemplates'}" name="Email Templates" icon="ti-email"/>
          <sidebar-link v-if="canViewLink('view-email')" :to="{name: 'emails'}" name="Emails" icon="ti-email"/>
          <sidebar-link v-if="canViewLink('view-email-broadcast')" :to="{name: 'emailBroadcast'}" name="Email Broadcast" icon="ti-email"/>
          <sidebar-link v-if="canViewLink('view-banner')" :to="{name: 'banners'}" name="Banners" icon="ti-layout-slider-alt"/>
          <sidebar-link v-if="canViewLink('view-config')" :to="{name: 'adminStatisticNumbers'}" name="Statistic Numbers" icon="ti-stats-up"/>
          <sidebar-link :to="{name: 'twofa'}" name="Two-Factor Auth" icon="ti-lock"/>
          <sidebar-link v-if="canViewLink('view-rm-dashboard')" :to="{name: 'rmDashboard'}" name="RM Dashboard" icon="ti-package"/>
          <sidebar-link v-if="canViewLink('view-maintenance')" :to="{name: 'maintenance'}" name="Maintenance" icon="ti-alert"/>
          <sidebar-link v-if="canViewLink('view-video-playlist')" :to="{name: 'videoPlaylists'}" name="Video Playlists" icon="ti-layout-list-thumb"/>
          <sidebar-link v-if="canViewLink('view-video')" :to="{name: 'videos'}" name="Videos" icon="ti-video-clapper"/>
          <sidebar-link v-if="canViewLink('view-document-versioning')" :to="{name: 'documentVersioning'}" name="Document Versioning" icon="ti-book"/>
          <sidebar-link v-if="canViewLink('view-attestation-report')" :to="{name: 'attestationReports'}" name="Attestation Reports" icon="ti-files"/>
          <!-- <sidebar-link :to="{name: 'adminStats'}" name="User Profile" icon="ti-user"/>
          <sidebar-link :to="{name: 'adminTableList'}" name="Table List" icon="ti-view-list-alt"/>
          <sidebar-link :to="{name: 'adminTypography'}" name="Typography" icon="ti-text"/>
          <sidebar-link :to="{name: 'adminIcons'}" name="Icons" icon="ti-pencil-alt2"/>
          <sidebar-link :to="{name: 'adminMaps'}" name="Map" icon="ti-map"/>
          <sidebar-link :to="{name: 'adminNotifications'}" name="Notifications" icon="ti-bell"/> -->
        </template>
        <mobile-menu>
          <!-- <li class="nav-item">
            <a class="nav-link">
              <i class="ti-panel"></i>
              <p>Stats</p>
            </a>
          </li> -->
          <!-- <drop-down class="nav-item"
                    title="5 Notifications"
                    title-classes="nav-link"
                    icon="ti-bell">
            <a class="dropdown-item">Notification 1</a>
            <a class="dropdown-item">Notification 2</a>
            <a class="dropdown-item">Notification 3</a>
            <a class="dropdown-item">Notification 4</a>
            <a class="dropdown-item">Another notification</a>
          </drop-down> -->
          <!-- <li class="nav-item">
            <a class="nav-link">
              <i class="ti-settings"></i>
              <p>Settings</p>
            </a>
          </li> -->
          <li class="divider"></li>
        </mobile-menu>
      </side-bar>
      <div class="main-panel menu-content">
        <top-navbar></top-navbar>

        <dashboard-content @click="toggleSidebar">

        </dashboard-content>

        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue"
import ContentFooter from "./ContentFooter.vue"
import DashboardContent from "./Content.vue"
import MobileMenu from "./MobileMenu"
import { userHasPermission } from "../../helpers/common";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    canViewLink(permissions) {
      return userHasPermission(permissions);
    },
  },
};
</script>

<style lang="scss">
  .menu-content {
    margin-top: -90px;
  }
</style>
