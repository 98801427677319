<template>
  <div v-if="isEnabledShowModal && document" id="id-goro-user-consent-modal" class="cls-custom-popup-container cls-user-consent-modal">
    <div class="popup-dialog popup-dialog-centered">
      <div class="popup-content">
        <div class="popup-body text-center">
          <div class="header text-left">
            <img class="logo" autofocus width="131" :src="require(`@/assets/img/logo_with_text.png`)" alt="GORO Sandbox"/>
          </div>
          <div class="body-content">
            <div class="heading-content">
              <h2 class="heading font-24 font-bold mt-2 mb-3">
                {{ document.title }}
              </h2>
            </div>
            <div class="content text-left">
              <div class="content-consent">
                <article>
                  <iframe 
                    ref="editorFrame"
                    class="editor-frame"
                    :srcdoc="iframeContent"
                  ></iframe>
                </article>
              </div>
            </div>
            <div class="footer-content mt-4 d-flex flex-column align-items-center justify-content-between">
              <div class="cls-check-not-show mb-4">
                <b-form-checkbox
                  id="checkbox-agree-providing-user-consent"
                  v-model="agree"
                  size="lg"
                  name="dont-show-again"
                >
                  {{ document.tick_content }}
                </b-form-checkbox>
              </div>
              <b-button @click="closePopup" class="btn btn-none btn-main font-18 font-bolder btn-close" style="padding: 6px 25px 7px 25px;" :disabled="!agree">
                {{ $t("common.AGREE") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/style.css";
import documentVersioningService from "../../services/document_versioning.service";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: null,
    },
  },
  emits: ["on-close"],
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      isEnabledShowModal: false,
      agree: false,
      scrollOptions: {
        suppressScrollX: true,
      },
    }
  },
  watch: {
    show(value) {
      this.isEnabledShowModal = value
    },
  },
  computed: {
    bodyContent() {
      if (!this.document || !this.document.content) {
        return ""
      }
      return this.document.content
    },
    iframeContent() {
      return `
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              body {
                font-family: "Figtree", Helvetica, sans-serif, serif;
                font-size: 16px;
                line-height: 1.3;
                color: #333333;
                margin: 0;
                padding: 10px;
                box-sizing: border-box;
                padding-right: 15px;
              }
            </style>
          </head>
          <body contenteditable="true">
          ${this.bodyContent}
          </body>
        </html>
      `
    },
  },
  methods: {
    onClose() {
      this.isEnabledShowModal = false
    },
    async closePopup() {
      await this.submitAgree()
      this.onClose()
      await this.$store.dispatch('getDocumentVersioning')
      this.$emit('on-close')
    },
    async submitAgree() {
      try {
        if (this.agree) {
          await documentVersioningService.markAgreeDocument({
            doc_uuid: this.document.uuid
          })
        }
      } catch (err) { }
    },
  },
}
</script>

<style lang="scss">
.cls-user-consent-modal{
  .popup-content{
    border-radius: 25px;
    max-width: 700px;
    padding: 25px;
    padding-top: 15px;
    border: 6px solid #00B7B6;
    @media(max-width: 992px) {
      padding: 15px;
      padding-top: 10px;
    }
    @media(max-width: 768px) {
      max-width: calc(100% - 1rem);
    }
    .popup-body{
      position: relative;
      z-index: 1;
      .header{
        .logo{
          @media(max-width: 992px) {
            width: 93px;
          }
        }
      }
      .body-content{
        padding: 20px;
        padding-top: 0;
        @media(max-width: 992px) {
          padding: 0px;
        }
        .heading-content{
          h2{
            line-height: 28.8px;
            text-align: center;
            color: #333333;
            @media(max-width: 992px) {
              // font-size: 20px !important;
              line-height: 19.2px;
            }
          }
          .notification-icon{
            @media(max-width: 992px) {
              width: 65px;
            }
          }
        }
        .content{
          height: 100%;
          .content-consent{
            padding: 0 10px;
            height: 100%;
            article{
              .editor-frame{
                width: 100%;
                height: 400px;
                border: 0 !important;
              }
            }
            @media(max-width: 992px) {
              padding: 0 14px 0 0;
            }
          }
          .goro-custom-scrollbar{
            height: 400px;
            // .ps__rail-y{
            //   height: 400px;
            // }
            // .ps__thumb-y {
            //   height: 245px !important;
            // }
          }
        }
        .footer-content{
          @media(max-width: 992px) {
            flex-direction: column;
          }
          .cls-check-not-show{
            .custom-control{
              @media(max-width: 992px) {
                margin-bottom: 15px;
              }
            }
            .custom-control-label{
              cursor: pointer;
              font-family: "Figtree", Helvetica, sans-serif;
              font-size: 15px;
              font-weight: 500;
              line-height: 21.6px;
              letter-spacing: -0.03em;
              padding: 3px 0px;
              color: #3F3F3F;
              text-align: justify;
              @media(max-width: 992px) {
                font-size: 14px !important;
              }
              &:before,
              &::after{
                top: 35% !important;
              }
            }
          }
          .btn-close{
            min-width: 164px;
          }
        }
      }
      
    }
  }
}
</style>