<template>
  <ModalSandbox 
  :show="ableToShowSandboxModal"
  @on-close="closeSandboxModal"
  />
  <ModalUserConsent 
  :document="documentActive"
  :show="ableToShowUserConsentModal"
  @on-close="closeUserConsentModal"/>
</template>

<script>
import ModalSandbox from "./ModalSandbox.vue"
import ModalUserConsent from "./ModalUserConsent.vue"
import { DOCUMENT_VERSIONING_TYPES } from "../../constants/constants"
import documentVersioningService from "../../services/document_versioning.service";
import pinService from "../../services/pin.service";
import { isUser } from "../../constants/roles";

export default {
  components: {
    ModalSandbox,
    ModalUserConsent,
  },
  data() {
    return {
      MODAL_TYPES: {
        SANDBOX: "SANDBOX",
        USER_CONSENT: "USER_CONSENT",
      },
      currentModal: "",
      isEnabledShowSandboxModal: true,
      isEnabledShowUserConsentModal: true,
      dontShowAgain: false,
    }
  },
  watch: {
    async $route(to, from) {
      setTimeout(async () => {
        await this.modalHandler()
      }, 1000)
    },
    async '$store.getters.installedPin'(value) {
      if (value) {
        await this.modalHandler()
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('getDocumentVersioning')
  },
  methods: {
    resetShowModal() {
      document.body.classList.remove("modal-open")
      this.isEnabledShowSandboxModal = false
      this.isEnabledShowUserConsentModal = false
    },
    addClassBody() {
      document.body.classList.add("modal-open")
    },
    async modalHandler(options = {}) {
      this.resetShowModal()
      const visibilityToShowSandbox = this.checkVisibilityToShowSandbox()

      if (visibilityToShowSandbox && !options.ignoreSandboxModal) {
        this.currentModal = this.MODAL_TYPES.SANDBOX
        this.isEnabledShowSandboxModal = true
        this.addClassBody()
      } else if (this.documentActive && isUser()) {
        if (this.isDocumentPopup) {
          if (this.checkVisibilityToShowUserConsent() && !options.ignoreUserConsentModal) {
            const checkShouldShowSecurityPin = await this.checkShouldShowSecurityPin()
            if (!checkShouldShowSecurityPin) {
              this.currentModal = this.MODAL_TYPES.USER_CONSENT
              this.isEnabledShowUserConsentModal = true
              this.addClassBody()
            }
          }
        } else {
          // Handle document page here
        }
      }
    },
    async checkShouldShowSecurityPin() {
      const res = await pinService.checkPin()
      if (res && !res.data) {
        return true
      }
      return false
    },
    checkVisibilityToShowSandbox() {
      const enabledConfig = this.$store.state.configs && this.$store.state.configs.popup_notification_sandbox_announcement_enabled
      if (!enabledConfig) {
        return false
      }

      const allowedPages = [
        "home",
        "homeForeigner",
      ]

      if (!allowedPages.includes(this.$route.name)) {
        return false
      }
      
      const visibilityToShow = this.checkModalVisibility()
      if (!!visibilityToShow) {
        return true
      }
      return false
    },
    checkModalVisibility() {
      const storedExpiry = localStorage.getItem("sanboxModalExpiry");
      if (storedExpiry && new Date(storedExpiry) > new Date()) {
        return false
      }
      return true
    },
    checkVisibilityToShowUserConsent() {
      const userLogged = this.userProfile

      return !!userLogged
    },
    
    async closeSandboxModal(params = {}) {
      this.isEnabledShowSandboxModal = false;
      await this.modalHandler({
        ignoreSandboxModal: true
      })
    },
    async closeUserConsentModal() {
      this.isEnabledShowUserConsentModal = false
      await this.modalHandler({
        ignoreSandboxModal: true,
        ignoreUserConsentModal: true
      })
    },
  },

  computed: {
    isDocumentPopup() {
      const documentPopupTypes = [
        DOCUMENT_VERSIONING_TYPES.USER_CONSENT
      ]
      return this.documentActive && documentPopupTypes.includes(this.documentActive.doc_type)
    },
    isDocumentPage() {
      const documentPageTypes = [
        DOCUMENT_VERSIONING_TYPES.PRIVACY_POLICY,
        DOCUMENT_VERSIONING_TYPES.TERMS_AND_CONDITION
      ]
      return this.documentActive && documentPageTypes.includes(this.documentActive.doc_type)
    },
    documentActive() {
      if (this.documentVersioning && this.documentVersioning[0]) {
        return this.documentVersioning[0]
      }
    },
    documentVersioning() {
      return this.$store.getters.documentVersioning
    },
    userProfile() {
      return this.$store.getters.userProfile;
    },
    ableToShowSandboxModal() {
      return (this.currentModal === this.MODAL_TYPES.SANDBOX) && this.isEnabledShowSandboxModal
    },
    ableToShowUserConsentModal() {
      return (this.currentModal === this.MODAL_TYPES.USER_CONSENT) && !this.ableToShowSandboxModal && this.isEnabledShowUserConsentModal
    },
  }
}
</script>

<style lang="scss">
.modal-open{
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
}
</style>