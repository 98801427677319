<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button v-if="canAddDocument" size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                    <b-button v-if="canAddConfig" class="ml-4" size="sm" id="show-btn"
                        @click="showAddModalMinimumVersions()">
                        Update Document Versions
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="documents.data" :columns="tableColumns" :imageColumns="imageColumns"
                        :htmlColumns="htmlColumns" :customActionTitles="customActions"
                        @onCustomAction="onClickedCustomAction" :show-delete-action="canDeleteDocument"
                        @onModalDelete="onModalDelete" @onModalEdit="onModalEdit">
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="8%">
                                    <b-form-select v-model="filters.doc_type" text-field="name" value-field="id">
                                        <template #first>
                                            <b-form-select-option :value="null">Select doc type</b-form-select-option>
                                        </template>
                                        <template v-for="docType in docTypes">
                                            <b-form-select-option :value="docType.value">
                                                {{ docType.title }}
                                            </b-form-select-option>
                                        </template>
                                    </b-form-select>
                                </th>
                                <th width="20%"></th>
                                <th width="22%"></th>
                                <th width="22%"></th>
                                <th width="15%"></th>
                                <th width="10%">
                                    <b-input v-model="filters.version"></b-input>
                                </th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="documents.total" align="right" v-model="documents.current_page"
                        :total-rows="documents.total" :per-page="documents.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-document-versioning :show="showModalCreate" :document="document" @on-close="onModalClose" />
        <modal-update-minimum-versions :show="showModalMinimumVersions" @on-close="onModalMinimumVersionsClose" />
        <b-modal v-model="modalDelete.show" header-bg-variant="light" header-text-variant="dark">
            <template #modal-header>
                <h5 class="modal-header-title mb-0">Delete confirm</h5>
                <button id="btn_closeModalDeleteDocument" type="button" class="close font-weight-bold"
                    aria-label="Close" @click="modalDelete.show = false">×</button>
            </template>
            <p>Are you sure you want to delete this document?<strong style="color: red">{{ modalDelete.content
                    }}</strong>
            </p>
            <template #modal-footer>
                <b-button variant="danger" size="sm" @click="onDeleteDocument">
                    Delete
                </b-button>
                <b-button variant="primary" size="sm" @click="modalDelete.show = false">
                    Close
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import documentsService from "../../services/admin/document.service";
import ModalCreateDocumentVersioning from "./modals/ModalCreateDocumentVersioning.vue";
import ModalUpdateMinimumVersions from "./modals/ModalUpdateMinimumVersions.vue";
import { userHasPermission } from "../../helpers/common";
import { USER_PERMISSIONS } from "../../constants/permissions";

const tableColumns = ["Id", "Type", "Title", "Content_En", "Content_Id", 'Tick', "Version"];

export default {
    components: {
        PaperTable,
        ModalCreateDocumentVersioning,
        ModalUpdateMinimumVersions,
    },
    data() {
        return {
            documents: {},
            docTypes: [
                {
                    value: 'TERMS_AND_CONDITION',
                    title: 'Terms & Condition'
                },
                {
                    value: 'PRIVACY_POLICY',
                    title: 'Privacy Policy'
                },
                {
                    value: 'USER_CONSENT',
                    title: 'User Consent'
                },
            ],
            tableColumns: [...tableColumns],
            htmlColumns: [2, 3, 4, 5],
            filters: {
                doc_type: null,
                version: null,
            },
            showModalCreate: false,
            showModalMinimumVersions: false,
            document: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
        };
    },
    async mounted() {
        await this.getDocuments(1);
    },
    watch: {
        'filters.doc_type'() {
            this.searchTimeOut(1);
        },
        'filters.version'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getDocuments(page) {
            const filters = {
                ...this.filters,
                page,
            };
            let result = await documentsService.getDocuments(filters);
            if (result && result.data) {
                result.data = result.data.map(e => {
                    return {
                        ...e,
                        type: this.docTypes.find(d => d.value === e.doc_type).title,
                        title: `
                        <div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="document-text-lang mr-2">EN</div>
                                <strong>${e.title_en}</strong>
                            </div>
                            <div class="d-flex flex-row align-items-center mt-2">
                                <div class="document-text-lang mr-2">ID</div>
                                <strong>${e.title_id}</strong>
                            </div>
                        </div>
                        `,
                        content_en: e.content_en,
                        content_id: e.content_id,
                        tick: `
                        <div>
                            <div class="d-flex flex-row align-items-center">
                                <div class="document-text-lang mr-2">EN</div>
                                <strong>${e.tick_content_en}</strong>
                            </div>
                            <div class="d-flex flex-row align-items-center mt-2">
                                <div class="document-text-lang mr-2">ID</div>
                                <strong>${e.tick_content_id}</strong>
                            </div>
                        </div>
                        `,
                    }
                })
                this.documents = result;
            }
        },

        async onChangePage(page) {
            await this.getDocuments(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getDocuments(page);
            }, 400);
        },

        showAddModal() {
            this.document = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalCreate = false;
            if (success) {
                this.getDocuments(this.documents.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.document = this.documents.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        async onDeleteDocument() {
            if (this.modalDelete.id) {
                await documentsService.deleteDocument(this.modalDelete.id);
                this.modalDelete = {
                    show: false,
                    content: '',
                    id: null,
                };
                await this.getDocuments(this.documents.current_page);
            }
        },

        onClickedCustomAction(data, key) {
            if (key === 'update-document') {
                this.document = this.documents.data.find((e) => e.id === data);
                this.showModalCreate = true;
            }
        },

        showAddModalMinimumVersions() {
            this.showModalMinimumVersions = true;
        },

        onModalMinimumVersionsClose() {
            this.showModalMinimumVersions = false;
        },
    },

    computed: {

        customActions() {
            if (this.canUpdateDocument) {
                return [
                    {
                        label: 'Modify',
                        key: 'update-document',
                    }
                ]
            }

            return []
        },
        canAddDocument() {
            return userHasPermission(USER_PERMISSIONS.DOCUMENT_VERSIONING.CREATE)
        },

        canUpdateDocument() {
            return userHasPermission(USER_PERMISSIONS.DOCUMENT_VERSIONING.UPDATE)
        },

        canDeleteDocument() {
            return userHasPermission(USER_PERMISSIONS.DOCUMENT_VERSIONING.DELETE)
        },

        canAddConfig() {
            return userHasPermission(USER_PERMISSIONS.CONFIG.CREATE_OR_UPDATE)
        },
    },
};
</script>
<style>
.document-text-lang {
    padding: 4px 16px;
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    width: 45px;
}

.text-description {
    font-size: 13px;
}
</style>