<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="pb-2">
                    <b-button size="sm" id="show-btn" @click="showAddModal()">
                        <b-icon icon="plus-lg"></b-icon>
                        Add new
                    </b-button>
                </div>
                <div slot="raw-content" class="table-responsive">
                    <paper-table :data="partners.data" :columns="tableColumns" :showEditAction="true"
                        @onModalEdit="onModalEdit" >
                        <template #theadSearch>
                            <thead class="search">
                                <th width="30px"></th>
                                <th width="100px">
                                    <b-input v-model="filters.name"></b-input>
                                </th>
                                <th width="10%">
                                    <b-input v-model="filters.email"></b-input>
                                </th>
                                <th>
                                    <multiselect id="key-recipients" v-model="filters.role_ids" :options="roles"
                                        :multiple="true" :close-on-select="false" :clear-on-select="true"
                                        :preserve-search="true" placeholder="Select roles" label="name" track-by="id"
                                        :allowEmpty="true" selectLabel="select" deselectLabel="remove">
                                    </multiselect>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="partners.total" align="right" v-model="partners.current_page"
                        :total-rows="partners.total" :per-page="partners.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <modal-create-partner :show="showModalCreate" @on-close="onModalClose" />
        <modal-update-partner-role :show="showModalEdit" :partner="this.partner" @on-close="onModalClose" />
    </div>
</template>
<script>
import { PaperTable } from "../../components";
import partnerService from "../../services/admin/partner.service";
import ModalCreatePartner from "./modals/ModalCreatePartner.vue";
import ModalUpdatePartnerRole from "./modals/ModalUpdatePartnerRole.vue";

const tableColumns = ["Id", "Name", "Email", "Roles", "2FA", "Status"];

export default {
    components: {
        PaperTable,
        ModalCreatePartner,
        ModalUpdatePartnerRole,
    },
    data() {
        return {
            partners: {},
            tableColumns: [...tableColumns],
            filters: {
                name: '',
                email: '',
                role_ids: null,
            },
            showModalEdit: false,
            showModalCreate: false,
            partner: null,
            modalDelete: {
                show: false,
                content: '',
                id: null,
            },
            roles: [],
        };
    },
    async mounted() {
        await this.getPartners(1);
    },
    watch: {
        'filters.name'() {
            this.searchTimeOut(1);
        },
        'filters.email'() {
            this.searchTimeOut(1);
        },
    },
    methods: {

        async getPartners(page) {
            const filters = {
                ...this.filters,
                role_ids: this.filters.role_ids ? this.filters.role_ids.map((e) => e.id) : null,
                page,
            };
            const result = await partnerService.getList(filters);
            if (result && result.data) {
                this.partners = result;
            }
        },

        async onChangePage(page) {
            await this.getPartners(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getPartners(page);
            }, 400);
        },

        showAddModal() {
            this.partner = null;
            this.showModalCreate = true;
        },

        onModalClose(success) {
            this.showModalEdit = false;
            this.showModalCreate = false;
            if (success) {
                this.getPartners(this.getPartners.current_page);
            }
        },

        onModalDelete(data) {
            this.modalDelete = data;
        },

        onModalEdit(data) {
            if (data) {
                this.partner = this.partners.data.find((e) => e.id === data);
                this.showModalEdit = true;
            }
        },
    }
};
</script>
<style scoped></style>
