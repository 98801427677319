<template>
  <div>
    <div class="row cls-transaction-page-container">
      <div class="col-12">
        <div class="d-flex cls-table-nav-filters flex-row align-items-center justify-content-between">
          <div class="d-flex flex-column align-items-start justify-content-start">
            <div class="mb-3">
              <b-button
                class="btn btn-none btn-main"
                style="padding: 5px 20px"
                size="sm"
                id="property-pick-date"
                @click="onShowModalPickDate()"
              >
                {{ $t("PARTNER.PICK_DATE") }}
              </b-button>
            </div>
            <div class="mb-3">
                <div class="cls-custom-filters-applied d-flex justify-content-start align-items-center">
                <p class="cls-applied-label">Filters: </p>
                <p v-if="!hasFiltered" class="cls-applied-label no-filtered">No filters</p>
                <div v-if="filters.from_created_at || filters.to_created_at" class="cls-applied-item cls-pick-date">
                    <span v-if="filters.from_created_at">{{ filters.from_created_at }}</span>
                    <span v-if="filters.to_created_at"> - {{ filters.to_created_at }}</span>
                    <button class="btn-remove-filter" @click="filters.created_at=null">
                        <b-icon icon="x-lg" style="color: gray;" scale="1.6"></b-icon>
                    </button>
                </div>
                </div>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
              <div id="export-label-info" class="d-flex flex-row align-items-center justify-content-start">
                <span>Export</span> <img class="img-info" src="@/assets/img/info-circle-fill.png" alt=""><span>:</span>
              </div>
              <b-tooltip variant="secondary" target="export-label-info" triggers="hover focus" placement="top">
                To proceed with the download, you must select a date and transaction status to display the transactions.
              </b-tooltip>
              <download-csv :disabled="isDisabledDownload" ref="downloadCsv" class="ml-2" :data="dataToExport" :fields="downloadFields"
                  :labels="downloadLabels" :name="csvFileName">
                  <div></div>
              </download-csv>
              <b-button :disabled="isDisabledDownload" class="download-btn" @click="exportCsv">csv
                  <b-icon icon="download" scale="0.8" />
              </b-button>
              <export-excel :disabled="isDisabledDownload" ref="downloadExcel" class="ml-2" :data="dataToExport" :fields="excelDownloadFields" :name="excelFileName">
                  <div></div>
              </export-excel>
              <b-button :disabled="isDisabledDownload" class="download-btn" @click="exportExcel">xls
                  <b-icon icon="download" scale="0.8" />
              </b-button>
          </div>
        </div>
        <div slot="raw-content" class="table-responsive">
          <paper-table
            class="cls-goro-custom-table"
            :hover="true"
            :data="items"
            :columns="tableColumns"
            :showEditAction="false"
            :customActionTitles="customActions"
            @onCustomAction="onClickedCustomAction"
            @onRowClicked="onRowClicked"
          >
            <template #theadSearch>
              <thead class="search">
                <th width="10%"></th>
                <th width="10%">
                  <b-input v-model="filters.external_id"></b-input>
                </th>
                <th width="10%">
                  <b-select :options="transactionTypes" v-model="filters.type">
                  </b-select>
                </th>
                <th width="5%"></th>
                <th width="5%">
                  <b-input v-model="filters.property_name"></b-input>
                </th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="10%">
                  <b-form-select
                    v-model="filters.status"
                    text-field="name"
                    value-field="id"
                  >
                    <template #first>
                      <b-form-select-option :value="''"
                        >Select status</b-form-select-option
                      >
                    </template>
                    <template v-for="status in statuses">
                      <b-form-select-option :value="status">
                        {{ status }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="60px"></th>
              </thead>
            </template>
          </paper-table>
          <b-pagination
            v-if="transactions.total"
            align="left"
            class="mt-3"
            v-model="transactions.current_page"
            :total-rows="transactions.total"
            :per-page="transactions.per_page"
            @change="onChangePage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
    </div>
    <modal-pick-date
      :show="showModalPickDate"
      @on-close="showModalPickDate = false"
      @on-apply="onApply"
    ></modal-pick-date>
    <modal-transaction-details :show="showModalTransactionDetails" :transaction="transaction" @on-close="onCloseTransactionDetails" />
  </div>
</template>
<script>
import moment from "moment";
import { PaperTable } from "../../components";
import transactionService from "../../services/partner/transactions.service";
import ModalTransactionDetails from "./modals/ModalTransactionDetails.vue";
import { exchange, getTransactionStatus } from "../../helpers/common";
import { STATUS, INDO, TRANSACTION_CUSTOM_ACTIONS } from "../../constants/constants";
import { USER_PERMISSIONS } from "../../constants/permissions";
import { partnerHasPermission } from "../../helpers/partner";
import ModalPickDate from "./modals/ModalPickDate.vue";

const tableColumns = [
  "uuid",
  "Trx_id",
  "Trx_type",
  "Property_id",
  "Property_name",
  "Num_of_tokens",
  "Amount",
  "Status",
  "Notes",
  "Created_at",
];

export default {
  components: {
    PaperTable,
    ModalTransactionDetails,
    ModalPickDate,
  },
  data() {
    return {
      transactions: {},
      items: [],
      tableColumns: [...tableColumns],
      filters: {
        external_id: "",
        property_name: "",
        type: "",
        status: "",
        created_at: null,
        from_created_at: "",
        to_created_at: "",
      },
      showModal: false,
      user: null,
      showModalTransactionDetails: false,
      showModalPickDate: false,
      transactionTypes: this.getAllTransactionTypes(),
      statuses: [
        STATUS.PENDING,
        STATUS.PAID,
        STATUS.APPROVED,
        STATUS.RECEIVED,
        STATUS.COMPLETED,
        STATUS.SWAPPED,
        STATUS.EXPIRED,
        STATUS.REFUNDED,
        STATUS.CANCELLED,
        STATUS.REJECTED,
        STATUS.FAILED,
      ],
      downloadFields: [
        "uuid",
        "trx_id",
        "trx_type",
        "property_id",
        "property_name",
        "num_of_tokens",
        "amount",
        "status",
        "notes",
        "created_at",
      ],
      downloadLabels: {
        "uuid": "uuid",
        "trx_id": "Trx_id",
        "trx_type": "Trx_type",
        "property_id": "Property_id",
        "property_name": "Property_name",
        "num_of_tokens": "Num_of_tokens",
        "amount": "Amount",
        "status": "Status",
        "notes": "Notes",
        "created_at": "Created at",
      },
      excelDownloadFields: {
        "uuid": "uuid",
        "Trx_id": "trx_id",
        "Trx_type": "trx_type",
        "Property_id": "property_id",
        "Property_name": "property_name",
        "Num_of_tokens": "num_of_tokens",
        "Amount": "amount",
        "Status": "status",
        "Notes": "notes",
        "Created at": "created_at",
      },
      dataToExport: [],
    };
  },
  async mounted() {
    await this.getTransactions();
  },
  watch: {
    "filters.uuid"() {
      this.searchTimeOut();
    },
    "filters.external_id"() {
      this.searchTimeOut();
    },
    "filters.property_name"() {
      this.searchTimeOut();
    },
    "filters.type"() {
      this.searchTimeOut();
    },
    "filters.status"() {
      this.searchTimeOut();
    },
    async "filters.created_at"(value) {
      this.filters.from_created_at = null;
      this.filters.to_created_at = null;

      if (value && value[0]) {
        this.filters.from_created_at = value[0];
      }
      if (value && value[1]) {
        this.filters.to_created_at = value[1];
      }
      this.searchTimeOut();
    },
  },
  computed: {
    isDisabledDownload() {
      const hasSelectedStatus = this.filters.status && this.filters.status !== ""
      return !this.filters.from_created_at || !hasSelectedStatus
    },
    hasFiltered() {
      if (this.filters.created_at) {
          return true
      }
      return false
    },
    customActions() {
      let actions = [];
      actions.push({
        ...TRANSACTION_CUSTOM_ACTIONS.VIEW_TRANSACTION_DETAILS,
        label: this.$t("PARTNER.TRANSACTION.DETAIL"),
        btnClass: "btn btn-none btn-main",
      });

      return actions;
    },
    fileName() {
      let name = ''
      if (this.filters.external_id) {
        name = `${name}_${this.filters.external_id}`
      }
      if (this.filters.property_name) {
        name = `${name}_${this.filters.property_name}`
      }
      if (this.filters.type) {
        name = `${name}_${this.filters.type}`
      }
      if (this.filters.status) {
        name = `${name}_${this.filters.status}`
      }
      let date = this.filters.from_created_at ? `_${this.filters.from_created_at}` : '';
      if (this.filters.to_created_at) {
          date = `${date}-${this.filters.to_created_at}`
      }
      return `transactions${name}${date}_${moment().format('YYYYMMDDHHmmss')}`;
    },

    csvFileName() {
      return `${this.fileName}.csv`;
    },

    excelFileName() {
      return `${this.fileName}.xls`;
    },
  },
  methods: {
    async getTransactions(page = 1) {
      const self = this
      const filters = {
        ...this.filters,
        page,
      };
      const result = await transactionService.getTransactions(filters);
      this.items = []
      if (result && result.data) {
        let { data } = result;
        this.transactions = result;
        this.items =
          (data &&
            data.map((e) => {
              const status = this.getStatus(e);
              return {
                id: e.id,
                uuid: e.uuid,
                property_id: (e.property && e.property.id) || "--",
                property_name: (e.property && e.property.name) || "--",
                type: e.type,
                trx_type: this.$t(`TRANSACTIONS_TYPES.${e.type}`),
                num_of_tokens: e.num_of_tokens || "--",
                amount: self.exchangeValue(e.amount + e.amount_from_balance + e.fee),
                status_value: status,
                status: this.$t(`TRANSACTIONS_STATUS.${status}`),
                notes: e.note,
                created_at: e.created_at,
                trx_id:
                  e.external_id ||
                  (e.payment && e.payment.external_id) ||
                  (e.withdrawal && e.withdrawal.external_id) ||
                  "--",
                item: e,
              };
            })) ||
          [];
      }
    },

    onShowModalPickDate() {
      this.showModalPickDate = true;
    },

    onCloseTransactionDetails() {
      this.showModalTransactionDetails = false;
      this.transaction = null;
    },

    onApply(dates) {
      this.filters.created_at = dates;
    },

    async onChangePage(page) {
      await this.getTransactions(page);
    },

    searchTimeOut(page = 1) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        await this.getTransactions(page);
      }, 400);
    },

    onClickedCustomAction(data, key) {
      const transaction = this.transactions.data.find((e) => e.id === data);
      if (key === TRANSACTION_CUSTOM_ACTIONS.VIEW_TRANSACTION_DETAILS.key) {
        this.transaction = transaction;
        this.showModalTransactionDetails = true;
      }
    },

    onRowClicked(data, item) {
      console.log(this.transactions, data)
      const transaction = this.transactions.data.find((e) => e.id === data);
      this.transaction = transaction;
      this.showModalTransactionDetails = true;
    },

    getStatus(e) {
      return getTransactionStatus(e);
    },

    exchangeValue(value) {
      return exchange(value, 100, false, INDO.CURRENCY)
    },

    getAllTransactionTypes() {
      const types = [
        "ALL",
        "BUY_TOKEN",
        "SELL_TOKEN",
        "ORDER_TOKEN",
        "REFERRAL_BONUS",
        "REFERRAL_BONUS_TOKEN",
        "WITHDRAWAL",
        "RENTAL_DISTRIBUTION",
        "BUY_TOKEN_VIRTUAL_BALANCE",
        "VIRTUAL_RENTAL_DISTRIBUTION",
      ];
      return types.map((e) => {
        return {
          text: this.$t(`TRANSACTIONS_TYPES.${e}`),
          value: e !== "ALL" ? e : "",
        };
      });
    },
    async exportCsv() {
      this.dataToExport = []
      await this.getExportData(1)
      this.$refs.downloadCsv.generate()
    },

    async exportExcel() {
      this.dataToExport = []
      await this.getExportData(1)
      this.$refs.downloadExcel.generate()
    },

    async getExportData(page = 1) {
      const self = this
      const filters = {
          ...this.filters,
          page,
          per_page: this.transactions?.total || 1000,
      };
      const result = await transactionService.getTransactions(filters);

      if (result && result.data) {
        let { data } = result;
        this.dataToExport = (data &&
          data.map((e) => {
            const status = this.getStatus(e);
            return {
              id: e.id,
              uuid: e.uuid,
              property_id: (e.property && e.property.id) || "--",
              property_name: (e.property && e.property.name) || "--",
              type: e.type,
              trx_type: this.$t(`TRANSACTIONS_TYPES.${e.type}`),
              num_of_tokens: e.num_of_tokens || "--",
              amount: self.exchangeValue(e.amount + e.amount_from_balance + e.fee),
              status_value: status,
              status: this.$t(`TRANSACTIONS_STATUS.${status}`),
              notes: e.note,
              created_at: e.created_at,
              trx_id:
                e.external_id ||
                (e.payment && e.payment.external_id) ||
                (e.withdrawal && e.withdrawal.external_id) ||
                "--",
            };
          })) ||
        [];
        await this.$nextTick()
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.cls-transaction-page-container{
  .cls-table-nav-filters{
    @media only screen and (max-width: 768px) {
      flex-direction: column !important;
      align-items: flex-start !important;
      margin-bottom: 15px;
    }
  }
  .download-btn {
      cursor: pointer;
      color: white;
      background-color: black;
      padding: 4px 10px 6px 10px;
      border-radius: 6px;
      &:hover{
          opacity: .7;
      }
      &.disabled,
      &:disabled{
        background-color: #6c757d;
      }
  }
  .img-info {
    width: 16px;
    height: 16px;
    background-color: transparent;
    z-index: 1;
  }
}
</style>
