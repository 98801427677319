<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div slot="raw-content" class="table-responsive">
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div class="d-flex flex-row">
                            <VueDatePicker class="mb-2" v-model="filters.date" model-type="dd/MM/yyyy" format="dd/MM/yyyy"
                                :range="true" style="width: 300px;" :enable-time-picker="false"
                                placeholder="Search date range" />
                            <b-input class="ml-2" v-model="filters.name" style="width: 300px;"
                                placeholder="Search name"></b-input>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <button class="btn btn-main white-normal font-14 mr-3" style="padding: 4px 12px" @click=refreshData>
                                Refresh <b-icon icon="arrow-clockwise" scale="0.8" />
                            </button>
                            Export:
                            <download-csv ref="downloadCsv" class="ml-2" :data="dataToExport" :fields="downloadFields"
                                :labels="downloadLabels" :name="csvFileName">
                                <div></div>
                            </download-csv>
                            <div class="download-btn" @click="exportCsv">csv
                                <b-icon icon="download" scale="0.8" />
                            </div>
                            <export-excel ref="downloadExcel" class="ml-2" :data="dataToExport" :fields="excelDownloadFields" :name="excelFileName">
                                <div></div>
                            </export-excel>
                            <div class="download-btn" @click="exportExcel">xls
                                <b-icon icon="download" scale="0.8" />
                            </div>
                        </div>
                    </div>
                    <paper-table :data="dashboardData.data" :columns="tableColumns">
                        <template #theadSearch>
                            <thead class="search">
                                <th> </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </thead>
                        </template>
                    </paper-table>
                    <b-pagination v-if="dashboardData.total" align="right" v-model="dashboardData.current_page"
                        :total-rows="dashboardData.total" :per-page="dashboardData.per_page" @change="onChangePage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { PaperTable } from "../../components";
import propertyDashboardService from "../../services/admin/propertyDashboard.service";
import { exchange } from '../../helpers/common';

const tableColumns = ["Date", "Name", "Buy Tokens", "Sell Tokens", "Referral Tokens", "Order Tokens", "Swapped Tokens", "Admin Buy Tokens", "Admin Sell Tokens", "Total Tokens", "Rental Distribution", "Balance Usage", "Tokens Left"];

export default {
    components: {
        PaperTable,
    },
    data() {
        return {
            dashboardData: {},
            tableColumns: [...tableColumns],
            filters: {
                name: '',
                start: '',
                end: '',
            },
            showModal: false,
            downloadFields: ['date', 'name', 'buy_tokens', 'sell_tokens', 'referral_tokens', 'order_tokens', "swapped_tokens", 'admin_buy_tokens', 'admin_sell_tokens', 'total_tokens', 'balance_usage', 'tokens_left'],
            downloadLabels: {
                date: 'Date',
                name: 'Name',
                buy_tokens: 'Buy Tokens',
                sell_tokens: 'Sell Tokens',
                referral_tokens: 'Referral Tokens',
                order_tokens: 'Order Tokens',
                swapped_tokens: 'Swapped Tokens',
                admin_buy_tokens: 'Admin Buy Tokens',
                admin_sell_tokens: 'Admin Sell Tokens',
                total_tokens: 'Total Tokens',
                balance_usage: 'Balance Usage',
                tokens_left: 'Tokens Left',
            },
            excelDownloadFields: {
                'Date': 'date',
                'Name': 'name',
                'Buy Tokens': 'buy_tokens',
                'Sell Tokens': 'sell_tokens',
                'Referral Tokens': 'referral_tokens',
                'Order Tokens': 'order_tokens',
                'Swapped Tokens': 'swapped_tokens',
                'Admin Buy Tokens': 'admin_buy_tokens',
                'Admin Sell Tokens': 'admin_sell_tokens',
                'Total Tokens': 'total_tokens',
                'Balance Usage': 'balance_usage',
                'Tokens Left': 'tokens_left',
            },
            dataToExport: [],
        };
    },
    async mounted() {
        await this.getDashboardData(1);
    },
    watch: {
        'filters.name'() {
            this.dataToExport = []
            this.searchTimeOut(1);
        },
        'filters.date'(value) {
            if (value && value.length === 2) {
                this.filters.start = value[0]
                this.filters.end = value[1]
                this.searchTimeOut(1);
            } else {
                this.filters.start = ''
                this.filters.end = ''
                this.searchTimeOut(1);
            }
            this.dataToExport = []
        },
    },
    methods: {

        async getDashboardData(page) {
            const filters = {
                ...this.filters,
                page,
            };
            const result = await propertyDashboardService.getPropertyStatisticalInfo(filters);
            if (result && result.data) {
                let data = result
                let items = data.data
                items = items.map(e => {
                    e.balance_usage = exchange(e.used_balance, 100, false, 'IDR')
                    e.rental_distribution = exchange(e.rent_distribution_amount, 100, false, 'IDR')
                    e.total_tokens = e.total_tokens || '0'
                    e.name = e.property && e .property.name || ''
                    e.tokens_left = e.remaining_tokens
                    return e
                })
                data.data = items
                this.dashboardData = data
            }
        },

        async refreshData() {
            await propertyDashboardService.refreshData()
        },

        async onChangePage(page) {
            await this.getDashboardData(page);
        },

        searchTimeOut(page) {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(async () => {
                await this.getDashboardData(page);
            }, 400);
        },

        async exportCsv() {
            if (!this.dataToExport.length) {
                await this.getExportData()
            }
            this.$refs.downloadCsv.generate()
        },

        async exportExcel() {
            if (!this.dataToExport.length) {
                await this.getExportData()
            }
            this.$refs.downloadExcel.generate()
        },

        async getExportData() {
            const filters = {
                ...this.filters,
                page: 1,
                per_page: 10000000,
            };
            const result = await propertyDashboardService.getPropertyStatisticalInfo(filters);
            if (result && result.data) {
                this.dataToExport = result.data.map(e => {
                    e.balance_usage = exchange(e.used_balance, 100, false, 'IDR')
                    e.rental_distribution = exchange(e.rent_distribution_amount, 100, false, 'IDR')
                    e.total_tokens = e.total_tokens || '0'
                    e.name = e.property && e .property.name || ''
                    e.tokens_left = e.remaining_tokens
                    return e
                })
                await this.$nextTick()
            }
        }
    },

    computed: {
        fileName() {
            const name = this.filters.name ? `_${this.filters.name}` : ''
            const date = this.filters.start && this.filters.end ? `_${this.filters.start}-${this.filters.end}` : '';
            return `export${name}${date}_${moment().format('YYYYMMDDHHmmss')}`;
        },

        csvFileName() {
            return `${this.fileName}.csv`;
        },

        excelFileName() {
            return `${this.fileName}.xls`;
        },
    },
};
</script>
<style scoped>
.download-btn {
    cursor: pointer;
    color: white;
    background-color: black;
    padding: 4px 10px 4px 10px;
    border-radius: 6px;
}
</style>

