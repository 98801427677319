const REGEX = {
  LEGAL_NAME: /^[a-zA-Z,.']+(?:\s[a-zA-Z,.']+)*$/
}

const INDO = {
  CALLING_CODE: "+62",
  COUNTRY_CODE: "62",
  ISO_COUNTRY_CODE: "ID",
  CURRENCY: "IDR",
}

const FOREIGNER = {
  LOCALE: "en-UK",
  CURRENCY: "USD",
  ROUTE_PATH_PREFIX: "/en",
  ROUTE_NAME_SUFFIX: "Foreigner"
}

const OTP_TYPE = {
  SMS: "sms",
  RADIST: "waba_radist",
  TWILIO: "waba_twilio",
}
const OTP_STATUS = {
  QUEUED: "Queued",
  DELIVERED: "Delivered",
  ERROR: "Error",
}
const STATUS = {
  WAITING: "WAITING",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  RECEIVED: "RECEIVED",
  FAILED: "FAILED",
  PAID: "PAID",
  EXPIRED: "EXPIRED",
  COMPLETED: "COMPLETED",
  REFUNDED: "REFUNDED",
  CANCELLED: "CANCELLED",
  SWAPPED: "SWAPPED"
}

const STATUS_CODE = {
  HTTP_UNAUTHORIZED: 401,
  HTTP_NOT_FOUND: 404,
  HTTP_TOO_EARLY: 425,
  HTTP_TOO_MANY_REQUESTS: 429,
  HTTP_UPGRADE_REQUIRED: 426,
  HTTP_PRECONDITION_REQUIRED: 428,
}

const TransactionType = {
  BUY_TOKEN: "BUY_TOKEN",
  ORDER_TOKEN: "ORDER_TOKEN",
  SELL_TOKEN: "SELL_TOKEN",
  REFERRAL_BONUS: "REFERRAL_BONUS",
  REFERRAL_BONUS_TOKEN: "REFERRAL_BONUS_TOKEN",
  WITHDRAWAL: "WITHDRAWAL",
  RENTAL_DISTRIBUTION: "RENTAL_DISTRIBUTION",
  ADMIN_ADD_BALANCE: "ADMIN_ADD_BALANCE",
  BUY_TOKEN_VIRTUAL_BALANCE: "BUY_TOKEN_VIRTUAL_BALANCE",
  ADMIN_DEDUCT_BALANCE: "ADMIN_DEDUCT_BALANCE",
  SYSTEM_ADD_BALANCE: "SYSTEM_ADD_BALANCE",
}

const PendingTaskType = {
  AGREE_TO_CONTRACT: "AGREE_TO_CONTRACT",
  CLAIM_VIRTUAL_RENTAL_INCOME: "CLAIM_VIRTUAL_RENTAL_INCOME",
  CLAIM_REFERRAL_BONUS: "CLAIM_REFERRAL_BONUS"
}

const TASK_STATUSES = {
  PENDING: "PENDING",
  EXPIRED: "EXPIRED",
  COMPLETED: "COMPLETED",
}

const ERROR_CODE = {
  KYC_REQUIRED: "ERROR_KYC_REQUIRED",
}

const FINANCIAL_SUBHEADER = {
  ASSET_VALUE: "ASSET_VALUE",
  ANNUAL_RETURN: "ANNUAL_RETURN",
}

const PAYMENT_METHOD = {
  XENDIT: "XENDIT",
  STRIPE: "STRIPE"
}

const WITHDRAWAL_METHOD = {
  XENDIT: "XENDIT",
  PAYPAL: "PAYPAL",
  BANK_ACCOUNT: "BANK_ACCOUNT"
}

const BANK_TYPE = {
  FOREIGNER: "FOREIGNER",
  INDO_USER: "INDO_USER"
}

const PROPERTY_STATUSES = {
  SOLD: "sold"
}

const PROPERTY_STATUSES_LIST = [
  { value: "", text: "Property status" },
  { value: "available", text: "Available" },
  { value: "promo", text: "Promo" },
  { value: "presale", text: "Presale" },
  { value: "sold", text: "Sold Out" },
  { value: "coming_soon", text: "Coming Soon" },
  { value: 'draft', text: 'Draft' },
]

const PROPERTY_CUSTOM_ACTIONS = {
  UPDATE_RENTAL_DISTRIBUTION: {
    label: 'Rental Distribution',
    key: 'update-rental-distribution'
  },
  VIEW_PROPERTY_HOLDER: {
    label: 'Holders',
    key: 'view-property-holder'
  },
  VIEW_PROPERTY_IMAGE: {
    label: 'Images Visibility',
    key: 'view-property-image'
  },
  VIEW_PROPERTY_MILESTONE: {
    label: 'Milestone',
    key: 'view-all-milestone'
  },
  VIEW_ALL_PROPERTY_FINANCIALS: {
    label: 'Financials',
    key: 'view-all-property-financials'
  },
  UPDATE_PROPERTY_STATUS_AND_TOKEN: {
    label: 'Update Status and Token',
    key: 'update-property-status-and-token'
  },
  VIEW_PROPERTY: {
    label: 'View',
    key: 'view-property'
  },
  UPDATE_PROPERY_DETAIL: {
    label: 'Modify',
    key: 'update-property-detail'
  },
  COPY_PROPERTY: {
    label: 'Copy',
    key: 'copy-property'
  },
}

const USER_CUSTOM_ACTIONS = {
  VIEW_USER_ASSETS: {
    label: 'Assets',
    key: 'view-user-assets'
  },
  VIEW_USER_VIRTUAL_ASSETS: {
    label: 'Virtual Assets',
    key: 'view-user-virtual-assets'
  },
  VIEW_USER_KTP_PASSPORT: {
    label: 'KTP/Passport',
    key: 'view-user-ktp-passport'
  },
  VIEW_USER_VERIFY_SELFIE: {
    label: 'Verify Selfie',
    key: 'view-user-verify-selfie'
  },
}

const PROPERTY_SHOW_MODE_STATUS = {
  ALL: "all"
}

const TRANSACTION_CUSTOM_ACTIONS = {
  VIEW_TRANSACTION_DETAILS: {
    label: 'Details',
    key: 'view-transaction-detail'
  },
}

const BLOCKCHAIN = {
  POLYGON_NETWORK: process.env.VUE_APP_POLYGON_NETWORK,
  POLYGON_SCAN_ADDRESS_URL: process.env.VUE_APP_POLYGON_SCAN_ADDRESS_URL,
  POLYGON_SCAN_TRANSACTION_URL: process.env.VUE_APP_POLYGON_SCAN_TRANSACTION_URL,
}

const ROUTE_META_PAGE_TYPES = {
  ADMIN: 'ADMIN',
  ACCOUNT: 'ACCOUNT',
  GUEST: 'GEST'
}

const ADMIN_LOGS_CUSTOM_ACTIONS = {
  SHOW_CHANGES: {
    label: 'Show Changes',
    key: 'show-changes'
  }
}

const PARTNER_PICK_DATE_VALUES = {
  DEFAULT: 31
}

const DATE_TIME_FORMATS = {
  PICK_DATE: "DD/MM/YYYY"
}

const STORAGE_ACTION_KEYS = {
  GET_UNREAD_COUNT: "get-unread-count",
  GET_PLAYLISTS: "get-playlists",
  GET_BANKS: "get-banks",
}

const DOCUMENT_VERSIONING_TYPES = {
  USER_CONSENT: "USER_CONSENT",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS_AND_CONDITION: "TERMS_AND_CONDITION"
}

export {
  REGEX,
  INDO,
  FOREIGNER,
  OTP_TYPE,
  OTP_STATUS,
  STATUS,
  STATUS_CODE,
  TransactionType,
  PendingTaskType,
  ERROR_CODE,
  FINANCIAL_SUBHEADER,
  PAYMENT_METHOD,
  WITHDRAWAL_METHOD,
  BANK_TYPE,
  PROPERTY_STATUSES,
  PROPERTY_STATUSES_LIST,
  PROPERTY_CUSTOM_ACTIONS,
  USER_CUSTOM_ACTIONS,
  PROPERTY_SHOW_MODE_STATUS,
  BLOCKCHAIN,
  ROUTE_META_PAGE_TYPES,
  ADMIN_LOGS_CUSTOM_ACTIONS,
  PARTNER_PICK_DATE_VALUES,
  DATE_TIME_FORMATS,
  TRANSACTION_CUSTOM_ACTIONS,
  STORAGE_ACTION_KEYS,
  TASK_STATUSES,
  DOCUMENT_VERSIONING_TYPES,
}
