<template>
    <b-modal v-model="showModal" id="modal-prevent-closing" ref="modal" title="Update Document Versions"
        @show="updateModal" @hidden="$emit('on-close')" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">

            <b-form-group label="Terms & Condition" label-for="key-input-tc" :state="validateState('terms_and_condition')">
                <b-form-input id="key-input-tc" v-model="form.terms_and_condition" type="number" :state="validateState('terms_and_condition')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="Privacy Policy" label-for="value-input-pp" :state="validateState('privacy_policy')">
                <b-form-input id="value-input-pp" v-model="form.privacy_policy" type="number" :state="validateState('privacy_policy')" required>
                </b-form-input>
            </b-form-group>

            <b-form-group label="User Consent" label-for="value-input-uc" :state="validateState('user_consent')">
                <b-form-input id="value-input-uc" v-model="form.user_consent" type="number" :state="validateState('user_consent')" required>
                </b-form-input>
            </b-form-group>
            <p>Notes: User must agree to these versions</p>
        </form>
    </b-modal>
</template>

<script>

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import documentsService from '../../../services/admin/document.service';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    validations() {
        return {
            form: {
                terms_and_condition: {
                    required,
                },
                privacy_policy: {
                    required,
                },
                user_consent: {
                    required,
                },
            },
        }
    },
    emits: ['on-close'],
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            form: {
                terms_and_condition: '',
                privacy_policy: '',
                user_consent: '',
            },
            showModal: false,
        }
    },

    watch: {
        show(value) {
            this.showModal = value
        }
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.v$.form[name];
            return $dirty ? !$error : null;
        },

        updateModal() {
            this.getConfigs()
        },

        async getConfigs() {
            const data = await documentsService.getVersionConfigs()
            if (data) {
                this.form = {
                    terms_and_condition: data.teams_and_condition,
                    privacy_policy: data.privacy_policy,
                    user_consent: data.user_consent,
                }
            }
        },

        resetModal() {
            this.form = {
                terms_and_condition: '',
                privacy_policy: '',
                user_consent: '',
            };
        },

        async handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            await this.handleSubmit()
        },

        async handleSubmit() {
            this.v$.form.$touch();
            const isFormCorrect = await this.v$.$validate()
            if (!isFormCorrect) {
                return;
            }
            await documentsService.updateVersionConfigs(this.form);
            this.$nextTick(() => {
                this.resetModal();
                this.$emit('on-close', true);
            })
        }
    },

    computed: {

    },
}
</script>

<style lang="scss" scoped></style>