<template>
  <header v-show="isShowNavigation" class="new-site-header-layout site-header site-navbar fixed-top" role="banner" style="z-index: 1030;">
    <location-reminder class="site-navbar-banner"></location-reminder>
    <div v-show="isShowBanner" class="site-navbar-banner goro-text-banner-section">
      <div class="content-banner">
        <p class="text font-18">
          <a :href="sanboxDocumentUrl" class="cls-text-link text font-18 p-0" target="_blank">
            {{ $t("TEXT_BANNER.TEXT") }}
          </a>
        </p>
      </div>
    </div>
    <div class="row align-items-center position-relative justify-content-between pt-0 pb-0" style="height: 100%;">
      <div class="pl-0 pr-0 d-none d-lg-block">
        <nav class="goro-site-nav-custom site-navigation site-navigation-left" role="navigation">
          <ul class="site-menu-custom d-none d-lg-block nav-menu">
            <li class="nav-item">
              <router-link class="nav-link pt-0 pb-0 mr-4" to="/">
                <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
              </router-link>
            </li>
            <li v-for="(menu, k) in getMenu()" :key="k" class="nav-item">
              <router-link v-if="menu.name" exact-active-class="active" :class="getClassNavLink(menu)" :to="{ name: menu.name }">
                {{ menu.title }}
              </router-link>
              <router-link v-else :exact-active-class="isActiveMenu(menu)" :class="getClassNavLink(menu)" :to="menu.href">
                {{ menu.title }}
              </router-link>
              <ul v-if="menu.child && menu.child.length > 0" class="sub-menu">
                <li v-for="(child, v) in menu.child" :key="v" class="sub-item">
                  <router-link exact-active-class="active" class="nav-link" :to="{ name: child.name, params: { ...child.params }, query: { ...child.query } }">
                    {{ child.title }}
                  </router-link>
                </li>
              </ul>
            </li>
            <li v-if="isShowGuestMenu" class="nav-item">
              <a :href=faqUrl target="_blank" class="nav-link" @click="trackGtmEvent(gtmEvent.FAQS)" >FAQ</a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="pl-0 pr-0 d-none d-xl-block">
        <nav class="site-navigation goro-site-nav-custom site-navigation-right text-right" role="navigation">
          <ul class="site-menu site-menu-custom">
            <li class="nav-item">
              <locale-switcher/>
            </li>
            <li class="nav-item">
              <div class="notification-container">
                <notification/>
              </div>
            </li>
            <li class="nav-item">
              <user-avatar/>
            </li>
            <li v-if="!userProfile">
              <router-link exact-active-class="active" class="nav-link pl-1 pr-2" :to="{name: 'login'}">
                <b-button id="btn_header_Login" class="btn-outline-main pt-1 pb-1 pl-3 pr-3" type="submit" variant="none">
                  {{ $t("AUTH.LOGIN") }}
                </b-button>
              </router-link>
            </li>
            <li v-if="!userProfile">
              <router-link class="pl-1 mr-4" :to="{name: 'register'}">
                <b-button id="btn_header_GetStarted" class="btn-main pt-1 pb-1" type="submit" variant="none" @click="trackGtmEvent(gtmEvent.GET_STARTED)">
                  {{ $t("AUTH.GET_STARTED") }}
                </b-button>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <!--Header for Mobile-->
      <div class="site-logo d-block d-lg-none">
        <router-link to="/">
          <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
        </router-link>
      </div>
      <div class="notification-container-mb mr-4 mb-3">
        <notification/>
      </div>
      <div class="menu-mobile toggle-button d-inline-block d-xl-none">
        <div class="bm-burger-button" @click="toggle" v-b-toggle.sidebar-backdrop>
          <span class="bm-burger-bars line-style" style="top: 0;"></span>
          <span class="bm-burger-bars line-style" style="top: 40%;"></span>
          <span class="bm-burger-bars line-style" style="top: 80%;"></span>
        </div>
        <b-sidebar id="sidebar-backdrop" v-if="open" @hidden="closeMenu" no-close-on-route-change right shadow width="100%" sidebar-class="mobile-sidebar-wrapper">
          <template #header="{ hide }">
            <div class="mobile-header-content">
              <router-link to="/">
                <img class="logo-img" src="../../assets/img/logo_with_text.png" alt="">
              </router-link>
              <b-button id="btn_header_HideMenu" class="close" @click="hide">
                <img class="logo-img" src="../../assets/img/icons/close-sidebar.png" alt="">
              </b-button>
            </div>
          </template>
          <div class="menu-content text-center">
            <ul class="site-menu site-navigation ml-auto d-lg-block">
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" to="/">{{$t("HEADER.HOME")}}</router-link></li>
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'marketplace'}">{{$t("HEADER.MARKETPLACE")}}</router-link></li>
              <li><router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'aboutUs'}">{{$t("HEADER.ABOUT_US")}}</router-link></li>
              <!-- <li><a :href=sellMyProperty target="_blank" v-b-toggle.sidebar-backdrop class="nav-link">{{ $t("HEADER.SELL_MY_PROPERTY") }}</a></li> -->
              <li><a :href=faqUrl target="_blank" v-b-toggle.sidebar-backdrop class="nav-link">FAQ</a></li>
              <li><locale-switcher/></li>
              <li><user-avatar v-b-toggle.sidebar-backdrop/></li>
              <li v-if="!userProfile">
                <router-link v-b-toggle.sidebar-backdrop exact-active-class="active" class="nav-link" :to="{name: 'login'}">
                  <b-button id="btn_sidebar_Login" class="btn-outline-main mt-1 mb-1 pl-3 pr-3" type="submit" variant="none">
                    {{ $t("AUTH.LOGIN") }}
                  </b-button>
                </router-link>
              </li>
              <li v-if="!userProfile">
                <router-link :to="{name: 'register'}" v-b-toggle.sidebar-backdrop>
                  <b-button id="btn_sidebar_GetStarted" class="btn-main mt-1 mb-1" type="submit" variant="none" @click="trackGtmEvent(gtmEvent.GET_STARTED)">
                    {{ $t("AUTH.GET_STARTED") }}
                  </b-button>
                </router-link>
              </li>
            </ul>
          </div>
        </b-sidebar>
      </div>
    </div>
  </header>
</template>
<script>
import LocationReminder from "./LocationReminder"
import UserAvatar from "@/components/UserAvatar"
import Notification from "@/components/Notification"
import LocaleSwitcher from "@/components/LocaleSwitcher"
import externalSites from "@/constants/externalSites"
import { gtmTrackEvent } from "../../helpers/gtm"
import { isShowMenuGoLearn } from "../../helpers/common"
import { GTM_EVENT_NAMES } from "../../constants/gtm"
import { ROUTE_META_PAGE_TYPES } from '../../constants/constants'

export default {
  components: {
    LocationReminder,
    UserAvatar,
    LocaleSwitcher,
    Notification,
  },
  data() {
    return {
      open: false,
      isOnMobile: true,
      sellMyProperty: externalSites.MAIL_TO.SELL_MY_PROPERTY,
      faqUrl: externalSites.FAQ,
      sanboxDocumentUrl: externalSites.SANDBOX,
      gtmEvent: {
        GET_STARTED: GTM_EVENT_NAMES.GET_STARTED,
        FAQS: GTM_EVENT_NAMES.FAQS,
      },
      isShowNavigation: true
    }
  },
  mounted () {
    this.handleWindowResize()
    window.addEventListener("resize", this.handleWindowResize)
  },

  beforeDestroy () {
    window.removeEventListener("resize", this.handleWindowResize)
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    isShowBanner () {
      return this.$route.name === "home" || this.$route.name === "homeForeigner"
    },
    isShowGuestMenu () {
      const routesShowMenuGuest = [
        'home',
        'homeForeigner'
      ]
      if (!this.userProfile || routesShowMenuGuest.includes(this.$route?.name)) {
        return true
      }
      return false
    },
    goLearnPlaylists () {
      return this.$store.getters.goLearnPlaylists
    },
  },
  methods: {
    handleWindowResize () {
      if (window.innerWidth <= 991) {
        this.isOnMobile = true
      } else {
        this.isOnMobile = false
      }
      this.checkShowNavigation(this.$route)
    },
    checkShowNavigation(to) {
      if (to && to.meta && to.meta.pageType === ROUTE_META_PAGE_TYPES.ACCOUNT) {
        if (!this.isOnMobile) {
          this.isShowNavigation = true
        } else {
          this.isShowNavigation = false
        }
      } else {
        this.isShowNavigation = true
      }
		},
    toggle() {
      this.open = !this.open
    },
    closeMenu() {
      this.open = false
    },
    trackGtmEvent(event) {
      gtmTrackEvent({
        event: event,
      })
    },
    getGoLearnMenus(prefix) {
      const goLearnPlaylists = this.goLearnPlaylists
      const childs = []
      if (goLearnPlaylists && goLearnPlaylists.length > 0) {
        goLearnPlaylists.map((playlist) => {
          childs.push({
            href: `${prefix}/golearn/${playlist.slug}`,
            title: `${playlist.title}`,
            name: 'goLearnPlaylist',
            params: {
              slug: playlist.slug
            }
          })
        })
      }

      const menu = {
        href: `${prefix}/golearn`,
        title: this.$t('GO_LEARN.GO_LEARN'),
        name: 'goLearnMain',
        child: childs
      }
      return menu
    },
    getMenu() {
      let prefix = ''
      if (this.$route.path.startsWith('/en')) {
        prefix = '/en'
      }

      let goLearnMenus = {}
      if (isShowMenuGoLearn(this.userProfile)) {
        goLearnMenus = this.getGoLearnMenus(prefix)
      }
      
      let menuWithLoggedin = [
        {
          href: `${prefix}/`,
          name: 'home',
          title: this.$t('HEADER.HOME'),
        },
        {
          href: `${prefix}/marketplace`,
          name: 'marketplace',
          title: this.$t('HEADER.MARKETPLACE'),
          
        },
        {
          href: `${prefix}#`,
          title: this.$t('NAVBAR.ACTIVITY_CENTER'),
          name: null,
          child: [
            {
              href: `${prefix}/account/assets-overview`,
              title: this.$t('account.ASSETS_OVERVIEW'),
              name: 'assetsOverview',
            },
            {
              href: `${prefix}/account/transactions`,
              title: this.$t('account.TRANSACTIONS'),
              name: 'transactions',
            },
            {
              href: `${prefix}/account/pending-tasks`,
              title: this.$t('PENDING_TASKS.PENDING_TASKS'),
              name: 'pendingTasks',
            },
            {
              href: `${prefix}/account/rental-income-reports`,
              title: this.$t('RENTAL_INCOME.RENTAL_INCOME_REPORTS'),
              name: 'rentalIncomeReports',
            },
            {
              href: `${prefix}/account/referrals`,
              title: this.$t('REFERRAL.REFERRALS'),
              name: 'referrals',
            },
          ],
        },
        {
          ...goLearnMenus
        },
        {
          href: `${prefix}#`,
          title: this.$t('NAVBAR.WITHDRAWLS'),
          name: null,
          child: [
            {
              href: `${prefix}/account/withdrawals/history`,
              title: this.$t('WITHDRAWALS.WITHDRAWAL_HISTORY'),
              name: 'withdrawalHistory',
            },
            {
              href: `${prefix}/account/withdrawals/bank-account-history`,
              title: this.$t('WITHDRAWALS.BANK_ACCOUNT'),
              name: 'bankAccountHistory',
            },
          ]
        },
        {
          href: `${prefix}#`,
          title: this.$t('NAVBAR.SECURITY'),
          name: null,
          child: [
            {
              href: `${prefix}/account/security/password`,
              title: this.$t('ACCOUNT_SECURITY.PASSWORD.TITLE'),
              name: 'password',
            },
            {
              href: `${prefix}/account/security/pin`,
              title: this.$t('PIN_SECURITY.CHANGE_PIN'),
              name: 'pin',
            },
            {
              href: `${prefix}/account/security/two-factor-auth`,
              title: this.$t('TWO_FA.TWO_FACTOR_AUTHENTICATION'),
              name: 'twoFactorAuth',
            },
          ]
        },
      ]
      menuWithLoggedin = menuWithLoggedin.filter(item => Object.keys(item).length > 0);

      let menuGuest = [
        {
          href: `${prefix}/`,
          name: 'home',
          title: this.$t('HEADER.HOME'),
          
        },
        {
          href: `${prefix}/marketplace`,
          name: 'marketplace',
          title: this.$t('HEADER.MARKETPLACE'),
          
        },
        {
          href: `${prefix}/about-us`,
          title: this.$t('HEADER.ABOUT_US'),
          name: 'aboutUs',
        },
      ]

      if (this.isShowGuestMenu) {
        return menuGuest
      } else if (this.userProfile) {
        return menuWithLoggedin
      } else {
        return menuGuest
      }
    },
    isActiveMenu(menu) {
      if (menu.child && menu.child.length > 0) {
        const active = menu.child.some(item => item.name === this.$route.name)
        return active ? 'active' : ''
      } else {
        return this.$route.fullPath === menu.href ? 'active' : ''
      }
    },
    getClassNavLink(menu) {
      if (menu.child && menu.child.length > 0) {
        return 'nav-link has-child-menu'
      } else {
        return 'nav-link'
      }
    },
  },
  watch: {
    isShowBanner (value) {
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"))
      })
    },
    async $route(to) {
      this.checkShowNavigation(to)
    }
  },
}
</script>
<style lang="scss">
.hide-header-footer .site-navbar {
  display: none;
}

.notification-container-mb {
  display: block;
  margin-top: 17px;
  @media (min-width: 992px) {
    display: none;
    margin-top: 17px;
  }
}

.notification-container {
  display: block;
  margin-top: 13px;
}

.mobile-sidebar-wrapper{
  header{
    border-bottom: 1px solid #EFECEC;
    justify-content: center;
    padding-bottom: 0.2rem !important;
    .mobile-header-content{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      button.close{
        position: absolute;
        top: 15px;
        right: 0px;
        width: 14px;
        height: 14px;
        background: transparent;
        opacity: 1;
        &:hover{
          opacity: .8;
        }
        img{
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .b-sidebar-body{
    .menu-content{
      ul.site-menu{
        padding: 0 !important;
        li{
          font-size: 18px;
        }
      }
    }
  }
}
.new-site-header-layout{
  &.site-navbar {
    margin-bottom: 0;
    z-index: 1030;
    width: 100%;
    padding: 0 5rem;
    border-bottom: 1px solid #ccc;
    @media (max-width: 991.98px) {
      margin-top: -5px;
      padding: 5px 2rem;
    }
    .site-navbar-banner {
      width: 100vw;
      margin-left: -5rem !important;
      margin-right: -5rem !important;
      @media (max-width: 991.98px) {
        width: 100vw;
        margin-left: -2rem !important;
        margin-right: -2rem !important;
      }
    }

    .toggle-button {
      position: absolute;
      right: 0;
    }
    .site-logo {
      position: absolute;
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 0;
      margin: 0;
      padding: 0;
      z-index: 3;
      @media (max-width: 991.98px) {
        float: left;
        position: relative;
      }
    }
    
    .toggle-button {
      position: absolute;
      right: 0;
    }

    .goro-site-nav-custom {
      *{
        font-family: "NewAcuminVariableConcept", Helvetica, sans-serif, serif;
      }
      .site-menu-custom{
        .nav-item{
          .locale-switcher{
            padding-top: 0;
            padding-bottom: 0;
            .dropdown{
              padding: 14px 0;
              .dropdown-menu{
                > li{
                  > a{
                    svg{
                      margin-top: -6px;
                    }
                    &:hover{
                      svg{
                        fill: var(--primary-menu-active-color);
                        stroke: var(--primary-menu-active-color);
                        margin-top: -6px;
                      }
                    }
                  }
                }
              }
            }
          }
          .goro-user-avatar-component{
            padding-top: 0;
            padding-bottom: 0;
            .dropdown{
              .btn-link{
                padding: 14px 0;
              }
            }
          }
        }
      }
      &.site-navigation-right{
        .site-menu-custom{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .notification-container{
          margin-top: 0 !important;
        }
      }
    }
  }
}
</style>
