import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
// import * as Cookies from 'js-cookie';
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

export default createStore({
  state: {
    geoLocation: {},
    stayAtCurrentLocation: false,
    switchToNewLocation: false,
    userProfile: null,
    isLoading: false,
    configs: {},
    unread: 0,
    availableBanks: [],
    referralCode: null,
    assetsCount: 0,
    pendingTasksCount: 0,
    initialBalance: 0,
    exchangeRates: null,
    socialLoginData: {},
    adminPermissions: [],
    required2FA: false,
    partnerPermissions: [],
    goLearnPlaylists: [],
    documentVersioning: [],
    installedPin: false,
  },
  getters,
  mutations,
  actions,
  plugins: [
    createPersistedState(),
    // {
    //   getState: (key) => Cookies.getJSON(key),
    //   setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true })
    // }
    // ),
  ],
});
