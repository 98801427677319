<template>
    <b-modal v-model="showModal" id="full-screen-modal" size="huge" header-bg-variant="dark" header-text-variant="light" @hidden="onHidden()">
        <template #modal-header>
            <h5 class="modal-header-title">{{ title }}</h5>
            <button type="button" class="close font-weight-bold" aria-label="Close" @click="onClose()">×</button>
        </template>
        <div class="container">
            <b-row>
                <b-col v-for="item in items" class="item" cols="12" lg="4">
                    <div class="item-container">
                        <p class="item-title">{{ item.name }}</p>
                        <p class="item-value">{{ item.value }}</p>
                    </div>
                </b-col>
            </b-row>
        </div>
        <template #modal-footer>
            <b-button v-if="isBan" size="sm" variant="success" @click="setUnban()">
                Unban
            </b-button>
            <div v-if="canUpdateWithdrawal">
                <b-button v-if="user.disabled_withdrawal" size="sm" variant="success" @click="setEnableWithdrawal()">
                    Enable Withdrawal
                </b-button>
                <b-button v-else size="sm" variant="secondary" @click="setDisableWithdrawal()">
                    Disable Withdrawal
                </b-button>
            </div>
            <b-button size="sm" variant="primary" @click="onClose()">
                Close
            </b-button>
        </template>
    </b-modal>
</template>
  
<script>

import moment from "moment";
import userService from "../../../services/admin/user.service";
import userStatus from "../../../constants/userStatus";
import { numberWithCommas, userHasPermission } from "../../../helpers/common";
import { USER_PERMISSIONS } from "../../../constants/permissions";

export default {
    emits: ['on-close'],
    props: {
        show: {
            type: Boolean,
            default: false,

        },
        title: {
            type: String,
            default: ""
        },
        initUser: {
            type: Object,
        },
    },
    data() {
        return {
            showModal: false,
            user: {},
            items: [],
            refresh: false,
        };
    },
    watch: {
        'initUser'(value) {
            this.user = value;
        },
        show(value) {
            this.showModal = value;
        },
        'user'(value) {
            this.items = [
                {
                    name: 'Name',
                    value: this.user.name,
                },
                {
                    name: 'Email',
                    value: this.user.email || '-',
                },
                {
                    name: 'Phone Number',
                    value: this.user.phone || '-',
                },
                {
                    name: 'Facebook Email',
                    value: this.user.email_fb || '-',
                },
                {
                    name: 'Google Email',
                    value: this.user.email_google || '-',
                },
                {
                    name: 'Apple Email',
                    value: this.user.email_apple || '-',
                },
                {
                    name: 'Date of birth',
                    value: this.user.dob || '-',
                },
                {
                    name: 'Verified',
                    value: this.getTime(this.user.verified_at) || '-',
                },
                {
                    name: 'Verified Email',
                    value: this.getTime(this.user.email_verified_at) || '-',
                },
                {
                    name: 'Street Address',
                    value: this.user.street_address || '-',
                },
                {
                    name: 'City',
                    value: this.user.city || '-',
                },
                {
                    name: 'State',
                    value: this.user.state && this.user.state.name || '-',
                },
                {
                    name: 'ZipCode',
                    value: this.user.zip_code || '-',
                },
                {
                    name: 'Country',
                    value: this.user.country && this.user.country.name || '-',
                },
                {
                    name: 'Country Code',
                    value: this.user.country_code,
                },
                {
                    name: 'ISO Country Code',
                    value: this.user.iso_country_code,
                },
                {
                    name: 'Balance',
                    value: `IDR${numberWithCommas(this.user.balance)}`,
                },
                {
                    name: 'Registered At',
                    value: this.getTime(this.user.created_at),
                },
                {
                    name: 'Withdrawal Status',
                    value: this.user.disabled_withdrawal ? 'DISABLED' : 'ENABLED',
                },
                {
                    name: 'Role',
                    value: this.user.role,
                },
                {
                    name: 'Status',
                    value: this.user.status,
                },
            ];
        }
    },
    async mounted() {

    },
    methods: {
        onHidden() {
            this.$emit('on-close', { refresh: this.refresh });
            this.refresh = false;
        },

        onClose() {
            this.showModal = false
        },

        async setUnban() {
            const data = await userService.unbanAccount({
                user_id: this.user.id,
            });
            if (data) {
                this.user = data;
                this.refresh = true;
            }
        },

        getTime(ts) {
            return ts ? moment(ts).format('DD/MM/yyyy HH:mm') : null;
        },

        async setEnableWithdrawal() {
            const data = await userService.updateWithdrawalStatus({
                user_id: this.user.id,
                status: 0,
            });
            if (data) {
                this.user = data;
                this.refresh = true;
            }
        },

        async setDisableWithdrawal() {
            const data = await userService.updateWithdrawalStatus({
                user_id: this.user.id,
                status: 1,
            });
            if (data) {
                this.user = data;
                this.refresh = true;
            }
        },
    },

    computed: {
        isBan() {
            return this.user.status === userStatus.Banned;
        },

        canUpdateWithdrawal() {
            return userHasPermission(USER_PERMISSIONS.USER.UPDATE_WITHDRAWAL_STATUS)
        },
    },
}
</script>
<style scoped>
:deep() #full-screen-modal {
    padding: 0 !important;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    margin: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
    background: #f4f3ef;
}

:deep() #full-screen-modal .modal-header {
    border-radius: 0;
}

.modal-header-title {
    margin: 0;
    font-weight: bold;
}

.close {
    color: #ffffff;
    opacity: .8;
    padding-bottom: 20px !important;
    /*!* opacity: .5; */
}

.item {
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.item-container {
    background-color: #ffffff;
    padding: 5px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(7, 55, 99, 0.16);
}

.item-title {
    font-size: 18px;
}

.item-value {
    font-size: 22px;
    font-weight: 600;
}
</style>
  