import serverApi from "../../utils/serverApi";

const attestationService = {
    getList: (data) => serverApi({ uri: "admin/attestation-resports", method: "GET", data }),

    createReport: (data) =>
        serverApi({ uri: "admin/attestation-resports", method: "POST", data }),

    updateReport: (id, data) =>
        serverApi({ uri: `admin/attestation-resports/${id}`, method: "PUT", data }),

    deleteReport: (id) =>
        serverApi({ uri: `admin/attestation-resports/${id}`, method: "DELETE" }),
};

export default attestationService;
